const ToggleControl = ({ name, isRequire, subTitle, iconName }) => {
  return (
    <>
      <div className="flex gap-2 items-start">
        <div className="text-primary-blue">{iconName}</div>
        <div className="flex flex-col gap-2 w-full">
          <div>
            <span className="text-base font-semibold"> {name}</span>
            {isRequire && <span className="text-red-200 ml-2">*</span>}
          </div>
          <span className="text-xs text-text-gray">{subTitle}</span>
        </div>
      </div>
    </>
  );
};
export default ToggleControl;
