import { createContext, useContext, useState } from "react";
import { BACKEND_URL } from "../utils/constant";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AuthPasswordLessContext = createContext();

export function useAuthPasswordLess() {
  return useContext(AuthPasswordLessContext);
}

export function AuthPasswordLessProvider({ children }) {
  const [emailField, setEmailField] = useState("");
  const [storeEmailID, setStoreEmailID] = useState("");
  const [otp, setOTP] = useState("");
  const [userCreatedData, setUserCreatedData] = useState("");
  const [refCode, setRefCode] = useState("");
  const [isAuthorise, setIsAuthorise] = useState("");
  const [OTPError, setOTPError] = useState("");
  const [resendOTPMessage, setResendOTPMessage] = useState(false);
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("currentUser")) || ""
  );

  const [storeGetClientData, setStoreGetClientData] = useState(() => {
    const clientData = localStorage.getItem("getClientData");
    if (clientData) {
      try {
        return JSON.parse(clientData);
      } catch (e) {
        console.error("Error parsing JSON from localStorage:", e);
        return "";
      }
    }
    return "";
  });

  const [responseData, setResponseData] = useState(null);
  const loginPath = localStorage.getItem("path");
  const sessionJwtToken = localStorage.getItem("sessionJwtToken");
  const history = useHistory();
  const [verifyPaymentLoading, setVerifyPaymentLoading] = useState(false);
  const [senderWalletAddress, setSenderWalletAddress] = useState(null);
  const [errorInVerify, setErrorInVerify] = useState("");
  const [paymentId, storepaymentId] = useState(null);
  const [verifyTransaction, setVerifyTransaction] = useState(null);
  const [verifyTransactionLoading, setVerifyTransactionLoading] =
    useState(false);
  const [offerData, setOfferData] = useState([]);
  const [verifyTransactionError, setVerifyTransactionError] = useState("");
  const [pauseOfferModal, setOpenPauseOfferModal] = useState(false);
  const [pauseOfferLoading, setPauseOfferLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const sendOTPEmail = async () => {
    try {
      await fetch(`${process.env.REACT_APP_BACKEND_URL}/passwordless`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        redirect: "follow",
        referrerPolicy: "no-referrer",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: emailField,
        }),
      })
        .then((res) => res.json())
        .then(({ data }) => {
          setStoreEmailID(data?.email_id);
          setUserCreatedData(data?.user_created);
          data && setResendOTPMessage(true);
        });
    } catch (err) {
      console.log(err);
      setResendOTPMessage(false);
    }
  };

  const handleCallPasswordLess = () => {
    sendOTPEmail();
  };

  const handleOTPSubmit = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/authenticate`,
        {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          redirect: "follow",
          referrerPolicy: "no-referrer",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            token: otp,
            method_id: storeEmailID,
            auth_method: "email",
          }),
        }
      );
      const { data, success } = await response.json();
      if (data?.sessionJwt) {
        localStorage.setItem("sessionJwtToken", data?.sessionJwt);
        await handleAuthorize(data?.sessionJwt);
        setOTP("");
      }

      if (!success) {
        setOTPError("OTP is invalid");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCallAuthenticate = () => {
    handleOTPSubmit();
  };

  const handleAuthorize = async (data) => {
    try {
      await fetch(`${process.env.REACT_APP_BACKEND_URL}/authorise`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        redirect: "follow",
        referrerPolicy: "no-referrer",
        headers: {
          "Content-Type": "application/json",
          "Auth-Method": "email",
          Authorization: data,
        },
      })
        .then((res) => res.json())
        .then(({ data, success }) => {
          setCurrentUser(data);
          localStorage.setItem("currentUser", JSON.stringify(data));
          if (!success) {
            handleLogoutCall();
            setIsAuthorise(false);
            history.push("/welcome");
          } else {
            setIsAuthorise(true);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const refereshAuthorise = () => {
    handleAuthorize(localStorage.getItem("sessionJwtToken"));
  };

  const callGetClient = async () => {
    await fetch(`${process.env.REACT_APP_BACKEND_URL}/get_client`, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionJwtToken,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    })
      .then((res) => res.json())
      .then(({ data, success }) => {
        setStoreGetClientData(data?.clientInfo);
        !data?.isClient && loginPath?.slice(1) === "sign-up" && callAddClient();
        localStorage.setItem("getClientData", JSON.stringify(data?.clientInfo));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const companyName = localStorage.getItem("companyName");
  const companySize = localStorage.getItem("companySize");

  const callAddClient = async () => {
    const formData = new FormData();
    formData.append("company_name", companyName);
    formData.append("company_size", companySize);

    await fetch(`${process.env.REACT_APP_BACKEND_URL}/add_client`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: formData,
      headers: {
        Authorization: sessionJwtToken,
      },
    })
      .then((res) => res.json())
      .then(({ success }) => {
        if (success) {
          callGetClient();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const callAddUser = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/add_user`,
        {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Authorization: sessionJwtToken,
          },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: JSON.stringify({
            uid: currentUser?.uid,
            email: currentUser?.email,
            referral_code: refCode,
          }),
        }
      );
      const { success } = await response.json();
      if (success) {
        callGetClient();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogout = async () => {
    try {
      await fetch(`${process.env.REACT_APP_BACKEND_URL}/logout`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        redirect: "follow",
        referrerPolicy: "no-referrer",
        headers: {
          "Content-Type": "application/json",
          Authorization: sessionJwtToken,
        },
      })
        .then((res) => res.json())
        .then(({ data }) => {});
    } catch (err) {
      console.log(err);
    }
  };

  const handleLogoutCall = () => {
    handleLogout();
    localStorage.removeItem("currentUser");
    localStorage.removeItem("sessionJwtToken");
    localStorage.removeItem("getClientData");
    setCurrentUser("");
    setStoreGetClientData("");
    localStorage.removeItem("companySize");
    localStorage.removeItem("companyName");
    history.push("/welcome");
    setResponseData(null);
  };

  //API for get all the capaign, Adslist , and analtics
  const fetchCampaignList = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/campaign_list`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        redirect: "follow",
        referrerPolicy: "no-referrer",
        headers: {
          "Content-Type": "application/json",
          Authorization: sessionJwtToken,
        },
        body: JSON.stringify({
          client_id: storeGetClientData?.clientId,
          sort_by: null,
          sort_order: null,
        }),
      });
      // console.log(response.status);
      // if (response && response.status === 401) {
      //   console.log("unauthenticated");
      // }
      const result = await response.json();
      setResponseData(result?.data);
    } catch (err) {
      console.error(err);
      // setError("An error occurred while fetching data");
    } finally {
    }
  };

  //API to call for verify offer

  const verifyOffer = async (offerId) => {
    setVerifyPaymentLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/verify_payment`,
        {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Authorization: sessionJwtToken,
          },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: JSON.stringify({
            wallet_address: senderWalletAddress,
            offer_id: offerId,
          }),
        }
      );
      const { success, data, message } = await response.json();
      if (success) {
        storepaymentId(data?.paymentId);
      } else {
        setErrorInVerify(message);
      }
      setVerifyPaymentLoading(false);
    } catch (error) {
      console.log(error);
      setVerifyPaymentLoading(false);
    }
  };

  // API for verify_offer_transaction

  const verifyOfferTransaction = async () => {
    setVerifyTransactionError("");
    setVerifyTransactionLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WEBHOOK_URL}/verify_offer_transaction`,
        {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Authorization: sessionJwtToken,
          },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: JSON.stringify({
            payment_id: paymentId,
          }),
        }
      );
      const { data, success, message } = await response.json();
      setVerifyTransaction(data);
      setVerifyTransactionLoading(false);
      if (!success) {
        setVerifyTransactionError(message);
      }
    } catch (error) {
      console.log(error);
      setVerifyTransactionLoading(false);
    }
  };

  // Api for client_offers
  const GetClientOffers = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/client_offers`,
        {
          method: "GET",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Authorization: sessionJwtToken,
          },
          redirect: "follow",
          referrerPolicy: "no-referrer",
        }
      );
      const { data } = await response.json();
      setOfferData(data);
    } catch (error) {
      console.log(error);
    }
  };
  //Api for update offer status
  const UpdateOfferStatus = async (id, status) => {
    setPauseOfferLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/update_offer_status`,
        {
          method: "PATCH",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Authorization: sessionJwtToken,
          },
          body: JSON.stringify({
            client_id: storeGetClientData?.clientId,
            offer_id: id,
            status: status,
          }),
          redirect: "follow",
          referrerPolicy: "no-referrer",
        }
      );
      const { success } = await response.json();
      if (success) {
        setOpenPauseOfferModal(false);
        GetClientOffers();
        setSelectedId(null);
      }
      setPauseOfferLoading(false);
    } catch (error) {
      console.log(error);
      setPauseOfferLoading(false);
    }
  };

  return (
    <AuthPasswordLessContext.Provider
      value={{
        storepaymentId,
        emailField,
        setEmailField,
        handleCallPasswordLess,
        otp,
        setOTP,
        handleCallAuthenticate,
        currentUser,
        setCurrentUser,
        handleLogoutCall,
        refereshAuthorise,
        setRefCode,
        isAuthorise,
        OTPError,
        setOTPError,
        resendOTPMessage,
        setResendOTPMessage,
        userCreatedData,
        callAddUser,
        callGetClient,
        storeGetClientData,
        fetchCampaignList,
        responseData,
        verifyPaymentLoading,
        errorInVerify,
        setSenderWalletAddress,
        senderWalletAddress,
        verifyOffer,
        paymentId,
        verifyOfferTransaction,
        verifyTransaction,
        verifyTransactionLoading,
        setErrorInVerify,
        verifyTransactionError,
        setVerifyTransactionError,
        offerData,
        GetClientOffers,
        UpdateOfferStatus,
        pauseOfferModal,
        setOpenPauseOfferModal,
        pauseOfferLoading,
        selectedId,
        setSelectedId,
        callAddClient,
      }}
    >
      {children}
    </AuthPasswordLessContext.Provider>
  );
}
