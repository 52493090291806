import { Warning } from "../../../icons/icons";

const Error = ({ message }) => {
  return (
    <div className="mt-4 flex items-center gap-3 md:gap-4 px-4 py-2.5 bg-red-100 rounded-2xl max-w-[480px]">
      <Warning className="text-red-200" />
      <span className="text-red-200 text-3.25">{message} </span>
    </div>
  );
};
export default Error;
