import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from "draft-js-export-html";

const EditorComponent = ({
  setEditorDescription,
  isRequiredEmpty,
  editorDescription,
  setEditorState,
  editorState,
  placeholder,
}) => {
  const editorOptions = {
    options: ["inline", "list", "link"],
    inline: {
      inDropdown: false,
      options: ["bold", "italic", "underline"],
    },
    list: { inDropdown: false, options: ["unordered", "ordered"] },
    link: {
      inDropdown: false,
      options: ["link"],
    },
  };

  const handleEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    // Convert editor content to plain text and update core_message
    const contentState = newEditorState.getCurrentContent();
    const editorContent = stateToHTML(contentState);
    setEditorDescription((prevState) => ({
      ...prevState,
      editorContent,
    }));
  };

  return (
    <div
      className={`border max-w-[480px] ${
        isRequiredEmpty &&
        (editorDescription.editorContent === undefined ||
          editorDescription.editorContent === "<p><br></p>")
          ? "border-red-500"
          : "border-border-gray"
      } rounded-lg py-3 px-6 h-40 `}
    >
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorStateChange}
        toolbar={editorOptions}
        editorStyle={{
          fontSize: "14px",
          height: "100px",
          marginTop: "15px",
        }}
        placeholder={placeholder}
      />
    </div>
  );
};
export default EditorComponent;
