import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useAuthPasswordLess } from "../../contexts/AuthPasswordLessContext";
import DragImageComponent from "../Overview/DragImageComponent";
import Error from "./offerComponents/Error";
import { LoaderIcon } from "../../icons/icons";

const CompanyLogoModal = ({ open, setOpenLogoModal, onClose }) => {
  const { callGetClient, storeGetClientData } = useAuthPasswordLess();

  const [updateClientLoading, setUpdateClientLoading] = useState(false);
  const [error, setError] = useState("");
  const sessionJwtToken = localStorage.getItem("sessionJwtToken");
  const [files, setFiles] = useState([]);

  const initialState = {
    picture: "",
    file_type: "",
  };

  const [showData, setShowData] = useState(initialState);

  // call update_client to store company url
  const callUpdateClient = async () => {
    setUpdateClientLoading(true);
    const formData = new FormData();
    formData.append("client_id", storeGetClientData?.clientId);
    const response = await fetch(showData?.picture);
    const blob = await response.blob();

    // Convert the blob to a File object
    const file = new File([blob], showData?.picture, {
      type: showData?.file_type,
    });

    // Append the File object to the FormData
    formData.append("company_logo", file);

    await fetch(`${process.env.REACT_APP_BACKEND_URL}/update_client`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: formData,
      headers: {
        Authorization: sessionJwtToken,
      },
    })
      .then((res) => res.json())
      .then(({ success, message }) => {
        if (success) {
          callGetClient();
          setOpenLogoModal(false);
          setUpdateClientLoading(false);
          //   history.push("/new-offer");
        } else {
          setError(message);
          setOpenLogoModal(true);
          setUpdateClientLoading(false);
          setFiles([]);
          setShowData(initialState);
        }
      })
      .catch((err) => {
        setUpdateClientLoading(false);
        console.log(err);
      });
  };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          onClose();
          setError("");
          setFiles([]);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25 " />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-150 transform overflow-hidden relative rounded-2xl p-20  bg-white text-left align-middle shadow-xl transition-all">
                <div className="cursor-pointer">
                  <img
                    src="/assets/icons/close.svg"
                    alt="close"
                    className="absolute top-7 right-8 h-8 w-8 z-10"
                    onClick={() => {
                      onClose();
                      setError("");
                      setFiles([]);
                    }}
                  />
                </div>
                <Dialog.Title
                  as="h3"
                  className="flex w-full justify-center items-center text-lg font-medium leading-6 text-gray-900"
                >
                  <div className="flex flex-col w-full">
                    <div className="flex items-center gap-4 justify-center">
                      <div className="p-2 bg-light-blue rounded-lg">
                        <img
                          src="/assets/icons/createCampaignIcons/gallery.svg"
                          alt="gallery icon"
                        />
                      </div>
                      <span className="font-semibold text-[26px]">
                        Company Logo
                      </span>
                    </div>
                    <div className="mt-10">
                      {error && <Error message={error} />}
                      <DragImageComponent
                        setFiles={setFiles}
                        setShowData={setShowData}
                        files={files}
                        setError={setError}
                        isVisibleName={false}
                      />

                      <button
                        className={` rounded-3xl font-bold w-full mt-10 py-4 text-sm flex items-center justify-center gap-4 ${
                          showData.picture === ""
                            ? "bg-bright-gray text-secondary-gray"
                            : "bg-primary-gradient text-white"
                        }`}
                        onClick={() => {
                          if (showData?.picture === "") {
                            setError("Please upload a company logo image");
                          } else if (showData?.file_type === "video/mp4") {
                            setError(
                              "Please upload an image in the company logo"
                            );
                            setFiles([]);
                            setShowData(initialState);
                          } else {
                            callUpdateClient();
                          }
                        }}
                        disabled={
                          updateClientLoading || showData?.picture === ""
                        }
                      >
                        🚀 Save & Deposit
                        {updateClientLoading ? (
                          <div className="flex items-center justify-center animate-spin">
                            <LoaderIcon />
                          </div>
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </div>
                </Dialog.Title>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default CompanyLogoModal;
