import { Listbox } from "@headlessui/react";

const TextSelect = ({ options, value, onChange }) => {
  return (
    <Listbox as="div" value={value} onChange={onChange}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button className={`w-full`}>
              <div className="text-3.25 whitespace-nowrap  focus:outline-none text-start justify-between flex items-center gap-1 sm:gap-2 w-full capitalize text-black-100 ">
                <span className="text-sm">{value}</span>

                <img src="/assets/icons/caret-down.svg" alt="caret icon" />
              </div>
            </Listbox.Button>
            {open && (
              <>
                <Listbox.Options
                  static
                  className="absolute mt-4 -ml-5 p-4  shadow-lg rounded-md z-10 list-none bg-white w-full min-w-max"
                >
                  {options.map((option, index) => (
                    <Listbox.Option key={option?.value} value={option.label}>
                      <div className="flex items-center pt-1.5">
                        <div
                          className={`py-2.5 p-4 w-full cursor-pointer group hover:bg-[#F5F8FF] rounded-lg flex gap-2 items-center ${
                            value === options[index].label
                              ? "bg-[#F5F8FF] text-primary-blue"
                              : "text-text-gray"
                          }`}
                        >
                          {option.logo && (
                            <img
                              src={`/assets/icons/${option.logo}`}
                              alt="logo blockchain"
                              className="w-4 h-4"
                            />
                          )}
                          <span className="text-sm">{option?.label}</span>
                        </div>
                      </div>
                      <div className="border-b border-border-gray pb-1.5" />
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </>
            )}
          </div>
        </>
      )}
    </Listbox>
  );
};

export default TextSelect;
