import { Link, useHistory, useParams } from "react-router-dom";
import { useAuthPasswordLess } from "../../contexts/AuthPasswordLessContext";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const LoginMail = () => {
  const { handleCallPasswordLess, emailField, setEmailField, setRefCode } =
    useAuthPasswordLess();
  const { id } = useParams();

  const history = useHistory();
  const currentYear = new Date().getFullYear();
  const { pathname } = useLocation();

  const handleMailSubmit = async () => {
    localStorage.setItem("path", pathname);
    // e.preventDefault();
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(emailField)) {
      // Display an error message to the user
      alert("Please enter a valid email address.");
    } else {
      await handleCallPasswordLess();
      setRefCode(id);
      history.push("/validate");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleMailSubmit();
    }
  };

  return (
    <>
      <div className=" h-screen relative">
        <div className="h-full flex justify-center items-center  absolute top-0 left-0 right-0 ">
          <div className="border border-border-gray rounded-3xl px-[42px] py-10 bg-white mx-7 md:mx-0 shadow-cardShadow max-w-[520px] w-full">
            <div className="flex justify-center flex-col items-center">
              <img src="/assets/linkko.svg" alt="linkko logo" />
              <span className="text-base text-gray-150 mt-2">
                Get the most of web3 users{" "}
              </span>
            </div>
            <div className="mt-16 mb-4">
              <span className="text-base">Email</span>
            </div>
            <div className="p-px hover:bg-primary-gradient mt-4 rounded-lg">
              <div className="rounded-lg hover:border-transparent border border-border-gray flex px-6 py-1.5 hover:bg-white bg-white">
                <input
                  type="email"
                  placeholder="Enter Your Email"
                  required
                  value={emailField}
                  onChange={(e) => setEmailField(e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e)}
                  className="border-none text-sm font-medium focus:ring-0 focus:outline-none w-full placeholder:text-secondary-gray text-black"
                />
              </div>
            </div>
            <div
              className="bg-primary-gradient rounded-3xl px-6 py-3.5 mt-4 flex gap-2 items-center justify-center cursor-pointer"
              onClick={handleMailSubmit}
            >
              <span className="text-white font-bold text-sm">Continue</span>
              <img src="/assets/icons//white-right.svg" alt="right arrow" />
            </div>
            <div className="mt-5 mb-10 text-center">
              <span className=" text-gray-150 text-3.25">
                By connecting to Linkko, you agree to our{" "}
                <Link
                  to="https://www.linkko.io/privacy-policy"
                  target="_blank"
                  className="text-primary-blue font-semibold border-b border-primary-blue"
                >
                  privacy policy
                </Link>
              </span>
            </div>
          </div>
        </div>
        <img
          src="/assets/images/bottom-right.png"
          alt="bottom"
          className="absolute bottom-0 h-40 md:h-auto object-contain"
        />

        <img
          src="/assets/images/top-right.png"
          alt="top"
          className="absolute top-0 right-0 w-60 object-contain md:w-auto xl:w-[450px]"
        />
        <div className="absolute bottom-10 w-full flex justify-center">
          <span className="text-gray-100 text-3.25 font-medium">
            © Linkko {currentYear}. All right reserved
          </span>
        </div>
      </div>
    </>
  );
};
export default LoginMail;
