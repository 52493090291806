import {
  CopyIcon,
  Discount,
  DollarSquare,
  EmptyWallet,
  InfoQuestion,
  LeftArrow,
  LoaderIcon,
  Pencil,
  RoundedClock,
  RoundedQuestionMark,
  TickMark,
  Warning,
} from "../../icons/icons";
import Error from "../common/offerComponents/Error";
import { useAuthPasswordLess } from "../../contexts/AuthPasswordLessContext";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const VerifyOffer = ({
  setOpenVerifyScreen,
  setUpdateOffer,
  totalDeposit,
  rewardAmount,
  totalParticipant,
  offerId,
  isVisible,
}) => {
  const [copyText, setCopyText] = useState("Copy");
  const blockChainRegex = /^0x[a-fA-F0-9]{40}$/;
  const history = useHistory();
  const {
    verifyPaymentLoading,
    errorInVerify,
    setSenderWalletAddress,
    senderWalletAddress,
    verifyOffer,
    paymentId,
    verifyOfferTransaction,
    verifyTransaction,
    verifyTransactionLoading,
    setErrorInVerify,
    currentUser,
    storepaymentId,
    verifyTransactionError,
    setVerifyTransactionError,
  } = useAuthPasswordLess();
  const handleCopyText = () => {
    navigator.clipboard.writeText(`0x50951D9CbC51e4113E74B9fB5c384D919D47785a`);
    setCopyText("Copied!");
    setTimeout(() => {
      setCopyText("Copy");
    }, 5000);
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex gap-2 items-start">
          <div className="text-primary-blue p-2 rounded-lg bg-light-blue w-fit">
            <Discount />
          </div>
          <div className="flex flex-col gap-2">
            <span className="text-base font-bold">Verify Cashback Offer</span>
            <span className="text-xs text-secondary-gray">
              Verify your cashback offer setup
            </span>
          </div>
        </div>
      </div>
      <div className="flex gap-4">
        {isVisible ? (
          <div
            className="mt-8 px-4 py-2 bg-light-blue flex items-center gap-1.5 rounded-2xl w-fit cursor-pointer"
            onClick={() => {
              history.push("/new-offer");
              setUpdateOffer(true);
              setOpenVerifyScreen(false);
              setVerifyTransactionError("");
            }}
          >
            <Pencil />
            <span className="text-3.25 text-primary-blue font-semibold leading-5">
              {" "}
              Edit Offer
            </span>
          </div>
        ) : (
          <div
            onClick={() => setOpenVerifyScreen(false)}
            className="flex gap-2 items-center cursor-pointer whitespace-nowrap mt-8"
          >
            <img src="/assets/icons/left_blue.svg" alt="back" />
            <span className="text-base font-semibold text-primary-blue">
              Back
            </span>
          </div>
        )}

        {paymentId && (
          <div
            className="mt-8 px-4 py-2 bg-light-blue flex items-center gap-1.5 rounded-2xl w-fit cursor-pointer"
            onClick={() => {
              storepaymentId(null);
              setSenderWalletAddress(null);
            }}
          >
            <LeftArrow />
            <span className="text-3.25 text-primary-blue font-semibold leading-5">
              {" "}
              Back
            </span>
          </div>
        )}
      </div>

      <div className="flex gap-10 w-full">
        <div className="flex flex-col items-center w-1/2">
          <div className="mt-10 flex">
            <div className="w-8 h-8 rounded-full bg-primary-gradient border-4 border-blue-100">
              {paymentId && <TickMark className="m-auto h-full text-white" />}
            </div>
            <div className="border-t-4 w-36 mt-3 border-blue-50"></div>
            <div
              className={`w-8 h-8 rounded-full 
             border-4 border-blue-100 ${
               paymentId ? " bg-primary-gradient" : "bg-light-blue"
             }`}
            >
              {verifyTransaction && (
                <TickMark className="m-auto h-full text-white" />
              )}
            </div>
          </div>
          <div className="flex justify-between w-56 mt-2">
            <div className="flex flex-col gap-1 items-center">
              <span className="font-bold text-base">Step 1</span>
              <span className="text-text-gray text-3.25">Add address</span>
            </div>
            <div className="flex flex-col gap-1 items-center">
              <span className="font-bold text-base">Step 2</span>
              <span className="text-text-gray text-3.25">Send funds</span>
            </div>
          </div>
          {!paymentId ? (
            <>
              <div className="mt-8 px-4 py-2 bg-light-blue flex items-center gap-1.5 rounded-2xl w-fit">
                <EmptyWallet />
                <span className="text-3.25 text-primary-blue flex gap-1">
                  Enter the wallet address you will send the funds from{" "}
                  <img
                    src="/assets/icons/blockchainIcons/polygon-matic-logo.svg"
                    alt="polygon"
                  />
                  <span className="font-bold">Polygon</span>
                </span>
              </div>
              <div className="mt-2">
                <span className="text-3.25 text-text-gray">
                  Need to pay from another token / chain?{" "}
                  <a
                    href={"https://t.me/artzainnn"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="text-primary-blue font-semibold underline underline-offset-1">
                      Contact us here
                    </span>
                  </a>
                </span>
              </div>
              <div className="border border-primary-blue rounded-lg max-w-[480px] w-full border-opacity-25 mt-4">
                <div className="px-6 py-2.5 flex justify-between">
                  <input
                    type="text"
                    value={senderWalletAddress}
                    onChange={(e) => {
                      setSenderWalletAddress(e.target.value);
                      setErrorInVerify("");
                    }}
                    placeholder="Enter sender wallet address"
                    className=" w-full text-start border-transparent focus:ring-0 focus:border-transparent text-sm  placeholder:text-secondary-gray"
                  />
                </div>
              </div>
              {errorInVerify && <Error message={errorInVerify} />}
              <button
                className="bg-primary-gradient text-white text-3.25 w-48 rounded-2xl my-6"
                disabled={verifyPaymentLoading}
                onClick={() => {
                  if (senderWalletAddress) {
                    blockChainRegex.test(senderWalletAddress)
                      ? verifyOffer(offerId)
                      : setErrorInVerify(
                          "This address is not from the right network"
                        );
                  } else {
                    setErrorInVerify("Enter the network address");
                  }
                }}
              >
                {verifyPaymentLoading ? (
                  <div className="flex items-center justify-center animate-spin py-2.5">
                    <LoaderIcon />
                  </div>
                ) : (
                  <div className="py-3">Next</div>
                )}
              </button>
            </>
          ) : (
            <>
              <div className="mt-8 px-4 py-2 bg-light-blue flex items-center gap-1.5 rounded-2xl w-fit text-primary-blue ">
                <DollarSquare width={20} height={20} />
                <span className="text-3.25 text-primary-blue flex gap-1">
                  Send amount on{" "}
                  <img
                    src="/assets/icons/blockchainIcons/polygon-matic-logo.svg"
                    alt="polygon"
                  />
                  <span className="font-bold">Polygon</span>
                  network to the address below
                </span>
              </div>
              <div className="flex items-center bg-bright-gray gap-4 px-6 max-w-[480px] w-full border border-primary-blue rounded-lg border-opacity-25 mt-4">
                <span className="text-3.25">
                  0x50951D9CbC51e4113E74B9fB5c384D919D47785a
                </span>
                <div
                  className="px-5 py-[7px] bg-primary-gradient rounded-3xl cursor-pointer my-2.5"
                  onClick={handleCopyText}
                >
                  <div className="flex items-center gap-1">
                    {copyText === "Copied!" ? (
                      <TickMark className="text-white" />
                    ) : (
                      <CopyIcon className="text-white h-4" />
                    )}
                    <span className="text-3.25 font-medium text-white">
                      {copyText}
                    </span>
                  </div>
                </div>
              </div>
              {verifyTransaction ? (
                <div className="mt-4 flex items-center gap-2 px-4 py-2.5 bg-green-100 rounded-2xl max-w-[480px]">
                  <TickMark className="text-green-200" />
                  <span className="text-green-200 text-3.25">
                    Offer succesfully created
                  </span>
                </div>
              ) : (
                <>
                  {verifyTransaction === false && (
                    <div className="mt-4 flex items-center gap-3 md:gap-4 px-4 py-2.5 bg-yellow-100 rounded-2xl max-w-[480px]">
                      <Warning className="text-yellow-200" />
                      <span className="text-yellow-200 text-3.25">
                        We haven&apos;t yet recorded the payment. That can take
                        a few minutes
                      </span>
                    </div>
                  )}
                </>
              )}
              {verifyTransactionError && (
                <Error message={verifyTransactionError} />
              )}

              {!verifyTransaction ? (
                <div className="flex items-center flex-row gap-2">
                  <button
                    className="bg-primary-gradient text-white text-3.25 w-48 rounded-2xl my-6"
                    disabled={verifyTransactionLoading}
                    onClick={verifyOfferTransaction}
                  >
                    {verifyTransactionLoading ? (
                      <div className="flex items-center justify-center animate-spin py-2.5">
                        <LoaderIcon />
                      </div>
                    ) : (
                      <div className="py-3">
                        {verifyTransaction === false
                          ? "Verify Again"
                          : "Verify"}
                      </div>
                    )}
                  </button>
                  {verifyTransaction === false ? (
                    <button className="ml-4 flex items-center py-3 bg-sky-50 text-primary-blue w-48 justify-center rounded-2xl text-3.25 gap-2">
                      <InfoQuestion />
                      <a
                        href={`https://linkko.typeform.com/to/GyetDKul#email=${currentUser?.email}&uid=${currentUser?.uid}&from=deposit`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span>Help</span>
                      </a>
                    </button>
                  ) : (
                    <div className="group relative my-auto">
                      <RoundedQuestionMark className="text-primary-blue cursor-pointer" />
                      <span className="group-hover:visible invisible absolute bottom-4 z-50 left-2 px-3 py-2 text-[11px] text-white bg-black-100 rounded-lg rounded-bl-none whitespace-nowrap">
                        Once transaction completed, click on Verify{" "}
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <Link to="/offers-overview">
                  <button className="bg-primary-gradient text-white text-3.25 py-3 w-48  rounded-2xl my-6">
                    Go to overview
                  </button>
                </Link>
              )}
            </>
          )}
          <span className="text-text-gray text-3.25">
            By sending the deposit, I agree with the{" "}
            <a
              href="https://www.linkko.io/advertisers-terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="text-primary-blue font-semibold underline underline-offset-1 cursor-pointer">
                {" "}
                terms & conditions
              </span>
            </a>
          </span>
        </div>
        <div className="border border-primary-blue border-opacity-40 mt-10 w-1/2 rounded-2xl p-10 flex flex-col gap-6 items-center max-w-[480px]">
          <span className="text-base font-semibold">Amount to be paid</span>
          <div className="px-6 py-3 bg-bright-gray rounded-lg flex gap-2 items-center">
            <img
              src="/assets/icons/dollar.svg"
              alt="doller"
              className="w-6 h-6 object-cover"
            />
            <span className="font-bold text-xl">$ {totalDeposit} USDC</span>
          </div>
          <div>
            <div className="flex flex-col gap-2 items-center">
              <span className="font-bold text-xs mt-2">Cost per reward</span>
              <div className="flex flex-row gap-2 text-xs">
                <span>Number of reward max.</span>:
                <span>
                  {" "}
                  $ {(totalParticipant * rewardAmount).toLocaleString(
                    "en-US"
                  )}{" "}
                  USDC
                </span>
              </div>
              <div className="flex flex-row gap-2 text-xs">
                <span>Linkko max. fees (20%)</span>:
                <span>
                  ${" "}
                  {(
                    totalParticipant * rewardAmount * 1.2 -
                    totalParticipant * rewardAmount
                  ).toLocaleString("en-US")}{" "}
                  USDC
                </span>
              </div>
            </div>
            <div className="mt-4 flex items-start gap-3 px-4 py-2.5 bg-[#FAFAFA] rounded-2xl w-fit">
              <RoundedClock
                width={16}
                height={16}
                color="#8C8C8C"
                className="shrink-0"
              />
              <span className="text-text-gray text-3.25">
                Balance will be deducted after each conversion events.
                <br /> You can withdraw your remaining deposit at anytime{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default VerifyOffer;
