import { useDropzone } from "react-dropzone";
import { TrashIcon, VerifiedIcon } from "../../icons/icons";

const DragImageComponent = ({
  setFiles,
  setShowData,
  files,
  setError,
  isVisibleName,
  companyName,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );

      // Update showData with the latest preview URL
      setShowData((prevData) => ({
        ...prevData,
        picture: acceptedFiles[0]?.path,
        file_type: acceptedFiles[0].type,
      }));
    },
  });
  const removeAll = () => {
    setFiles([]);
    setShowData((prevState) => ({
      ...prevState,
      picture: "", // Clear the preview URL when removing files
    }));
    setShowData((prevState) => ({
      ...prevState,
      file_type: "",
    }));
    setError("");
  };

  const thumbs = files.map((file, i) => (
    <div key={file.name} className="flex justify-between items-center mt-4">
      {isVisibleName && (
        <div className="flex gap-4 items-center">
          {file.type.startsWith("image/") && (
            <img
              src={file.preview}
              // Revoke data uri after image is loaded
              alt="none"
              className="w-12 h-12 rounded-full object-contain"
              onLoad={() => {
                setShowData((prevState) => ({
                  ...prevState,
                  picture: file.preview,
                }));
              }}
            />
          )}
          <div className="flex items-center gap-1">
            <span className="text-sm">{companyName}</span>
            <VerifiedIcon />
          </div>
        </div>
      )}

      <div
        className="flex gap-2 items-center bg-bright-gray rounded-lg w-fit text-xs px-4 py-2 "
        onLoad={() => {
          setShowData((prevState) => ({
            ...prevState,
            file_type: file.type,
          }));
        }}
      >
        {file.type.startsWith("image/") ? (
          <img
            src={file.preview}
            // Revoke data uri after image is loaded
            alt="none"
            className="w-6 h-6"
            onLoad={() => {
              setShowData((prevState) => ({
                ...prevState,
                picture: file.preview,
              }));
            }}
          />
        ) : (
          <video width="24" height="24" controls>
            <source src={file.preview}></source>
          </video>
        )}

        {file.path}
        <button type="button" onClick={removeAll}>
          <TrashIcon className="w-4 h-4 text-secondary-gray" />
        </button>
      </div>
    </div>
  ));
  return (
    <div>
      {thumbs?.length >= 1 && (
        <div>
          <ul>{thumbs}</ul>
        </div>
      )}
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <div
          className="px-6 py-3 border border-dashed border-primary-blue rounded-2xl flex flex-col gap-1 cursor-pointer items-center mt-4"
          onClick={() => setError("")}
        >
          <span className="text-xs font-medium text-primary-blue">
            Upload or Drag other image here
          </span>
          <span className="text-2.5 italic text-primary-blue">
            Max 500 MB per upload
          </span>
        </div>
      </div>
    </div>
  );
};
export default DragImageComponent;
