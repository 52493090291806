import { useEffect } from "react";

const GradientButton = ({
  selectedCategory,
  setSelectedCategory,
  setSelectedOption,
  setBlockChainAddress,
  setDuration,
  setAddedAmount,
  setSelectedBlockchain,
  setTokenInfoData,
  updateOffer,
}) => {
  const cateGoryToken = [
    "💰 Token",
    "🏞️ NFT",
    "💵 DeFi",
    "🤝 DEX",
    "🔗 Offchain",
  ];

  useEffect(() => {
    if (selectedCategory && !updateOffer) {
      setBlockChainAddress("");
      setAddedAmount("");
      setDuration(null);
      setSelectedBlockchain("Polygon");
      setTokenInfoData("");
    }
  }, [selectedCategory]);

  return (
    <div className="flex flex-row gap-2 mt-6 overflow-auto scrollbar-thumb-primary-blue scrollbar-track-sky-50 scrollbar-thumb-rounded scrollbar-thin scrollbar-track-rounded-full">
      {cateGoryToken.map((data, index) => (
        <button
          className={`px-4 py-2 ${
            selectedCategory !== cateGoryToken[index]
              ? "bg-white text-text-gray border border-gray-300"
              : "bg-primary-gradient text-white border"
          } rounded-3xl flex items-center justify-center whitespace-nowrap`}
          key={index}
          onClick={() => {
            setSelectedCategory(data);
            setSelectedOption("");
          }}
        >
          <span className="text-3.25">{data}</span>
        </button>
      ))}
    </div>
  );
};
export default GradientButton;
