import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useAuthPasswordLess } from "../../contexts/AuthPasswordLessContext";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const SignUp = () => {
  const [companyName, setCompanyName] = useState("");
  const currentYear = new Date().getFullYear();
  const rangeData = ["1-10", "10-100", "100-1000", "1000+"];
  const [companySize, setCompanySize] = useState(null);
  const { handleCallPasswordLess, emailField, setEmailField } =
    useAuthPasswordLess();
  const history = useHistory();
  const { pathname } = useLocation();
  const [error, setError] = useState("");

  const handleMailSubmit = async () => {
    localStorage.setItem("path", pathname);
    if (companyName === "") {
      setError("Please fill company name");
    } else {
      localStorage.setItem("companyName", companyName);
      localStorage.setItem("companySize", companySize);

      // e.preventDefault();
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(emailField)) {
        // Display an error message to the user
        setError("Please enter a valid email address.");
      } else {
        await handleCallPasswordLess();
        history.push("/validate");
      }
    }
  };
  return (
    <>
      <div className="flex justify-between w-full h-screen relative">
        <div className="h-full flex justify-center items-center mx-auto">
          <div className="border border-border-gray rounded-3xl px-[42px] py-10 bg-white z-10 mx-7 md:mx-0">
            <div className="flex justify-center flex-col items-center">
              <img src="/assets/linkko.svg" alt="linkko logo" />
              <span className="text-base text-gray-150 mt-2">
                Turn attention into income
              </span>
            </div>
            <div className="mt-16">
              <span className="font-medium text-base">Company Name</span>
              <input
                type="email"
                placeholder="Enter Your Company Name"
                required
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                className="rounded-lg border border-secondary-gray border-opacity-20 px-6 py-3 text-sm font-medium focus:ring-0 focus:outline-none focus:border-primary-blue focus:border-opacity-25 w-full mt-2 placeholder:text-secondary-gray text-secondary-gray"
              />
            </div>
            <div className="mt-4">
              <span className="font-medium text-base">Company Size</span>
              <div className="mt-2 flex gap-2">
                {rangeData?.map((data, index) => (
                  <div
                    className={`border px-6 py-3 w-fit rounded-full cursor-pointer ${
                      companySize === data && "bg-gray-200"
                    }`}
                    key={index}
                    onClick={() => {
                      setCompanySize(data);
                    }}
                  >
                    {data}
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-4">
              <span className="font-medium text-base">Email</span>
              <input
                type="email"
                placeholder="Enter Your Email"
                required
                value={emailField}
                onChange={(e) => setEmailField(e.target.value)}
                // onKeyDown={(e) => handleKeyDown(e)}
                className="rounded-lg border border-secondary-gray border-opacity-20 px-6 py-3 text-sm font-medium focus:ring-0 focus:outline-none focus:border-primary-blue focus:border-opacity-25 w-full mt-2 placeholder:text-secondary-gray text-secondary-gray"
              />
            </div>
            <div
              className="bg-primary-blue rounded-3xl px-6 py-3.5 mt-4 flex gap-2 items-center justify-center cursor-pointer"
              onClick={handleMailSubmit}
            >
              <span className="text-white font-bold text-tiny">Continue</span>
              <img src="/assets/icons/white-right.svg" alt="right arrow" />
            </div>
            {error && (
              <div className="w-60 truncate mt-2 mx-auto md:mx-0 text-center md:text-start">
                <span className="text-xs text-red-200">{error}</span>
              </div>
            )}
          </div>
        </div>
        <div className="absolute bottom-0">
          <img src="/assets/images/bottom-right.png" alt="bottom shadow" />
        </div>
        <div className="absolute top-0 right-[25%]">
          <img src="/assets/images/top-right.png" alt="bottom shadow" />
        </div>
        <div className="relative">
          <img
            src="/assets/Clip_path_group.svg"
            alt="righr side banner"
            className="h-full object-cover"
          />
          <div className="absolute bottom-10 w-full flex justify-center">
            <span className="text-white text-[13px] font-medium">
              © Linkko {currentYear}. All right reserved
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default SignUp;
