import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Discount } from "../../icons/icons";
import { useAuthPasswordLess } from "../../contexts/AuthPasswordLessContext";

const PauseOfferModal = ({ open, onClose, selectedId, pauseOfferLoading }) => {
  const { UpdateOfferStatus } = useAuthPasswordLess();
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25 " />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-150 transform overflow-hidden relative rounded-2xl py-16 px-24  bg-white text-left align-middle shadow-xl transition-all">
                <div className="cursor-pointer">
                  <img
                    src="/assets/icons/close.svg"
                    alt="close"
                    className="absolute top-7 right-8 h-8 w-8 z-10"
                    onClick={onClose}
                  />
                </div>
                <Dialog.Title
                  as="h3"
                  className="flex w-full justify-center items-center text-lg font-medium leading-6 text-gray-900 flex-col gap-6"
                >
                  <div className="flex gap-2 items-start">
                    <div className="text-primary-blue p-2 rounded-lg bg-light-blue w-fit">
                      <Discount />
                    </div>
                    <div>
                      <span className="text-2xl font-bold">
                        Pause Cashback Offer{" "}
                      </span>
                    </div>
                  </div>
                  <div className="text-center">
                    <span className="text-gray-150 text-base">
                      Are you sure to do this action? Users will not be able to
                      participate while it&apos;s on paused
                    </span>
                  </div>
                  <div className="flex gap-4">
                    <button
                      className={`py-3 w-40 rounded-2xl text-3.25 bg-red-200 text-white ${
                        pauseOfferLoading && "opacity-25"
                      } `}
                      onClick={() => {
                        UpdateOfferStatus(selectedId, "pause");
                      }}
                      disabled={pauseOfferLoading}
                    >
                      Pause
                    </button>
                    <button
                      className="py-3 w-40 rounded-2xl text-3.25 bg-sky-50 text-primary-blue"
                      onClick={onClose}
                    >
                      Cancel{" "}
                    </button>
                  </div>
                </Dialog.Title>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default PauseOfferModal;
