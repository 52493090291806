import React from "react";
import { RadioGroup } from "@headlessui/react";

const RadioInput = ({
  options,
  selectedOption,
  setSelectedOption,
  isRequiredEmpty,
}) => {
  return (
    <RadioGroup value={selectedOption} onChange={setSelectedOption}>
      {/* <RadioGroup.Label>Choose a framework</RadioGroup.Label> */}
      <div className="flex flex-row gap-2 xl:gap-4">
        {options.map((option) => (
          <RadioGroup.Option
            key={option}
            value={option.toLowerCase()}
            className={() => `relative bg-white py-4 cursor-pointer flex`}
          >
            {({ checked }) => (
              <>
                <span
                  className={`${
                    isRequiredEmpty && !selectedOption
                      ? "border-red-500"
                      : checked
                      ? "border-blue-300"
                      : "bg-white border-blue-300"
                  } ring-1 ring-white h-5 w-5 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center`}
                  aria-hidden="true"
                >
                  <span
                    className={` ${
                      checked && "rounded-full bg-primary-blue w-3.5 h-3.5"
                    }`}
                  />
                </span>
                <span className="ml-1 xl:ml-2 font-medium text-gray-900 text-sm xl:text-base">
                  {option}
                </span>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default RadioInput;
