import { Listbox } from "@headlessui/react";
import { Category } from "../../../icons/icons";
// import { CategoryIcon } from "../../icons/icons";

const CategorySelect = ({ options, value, onChange }) => {
  return (
    <Listbox as="div" value={value} onChange={onChange}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button
              className={`text-3.25 whitespace-nowrap bg-white focus:outline-none text-start flex items-center gap-1 sm:gap-2 w-full capitalize text-black-100 border rounded-[40px] px-4 py-2`}
            >
              <Category
                className="text-primary-blue"
                width={17}
                height={17}
                color="#5E91FF"
              />
              <span className="text-3.25 text-gray-100">Categories:</span>
              <span className="w-16">{value ? value : "All"}</span>
              <img src="/assets/icons/arrow-down.svg" alt="caret icon" />
            </Listbox.Button>
            {open && (
              <>
                <Listbox.Options
                  static
                  className="absolute mt-1 w-full p-4 bg-white shadow-lg rounded-md z-10 mx-0"
                >
                  {options.map((option) => (
                    <Listbox.Option key={option?.value} value={option.value}>
                      <div className="flex items-center pt-1.5">
                        <div className="py-2.5 p-4 cursor-pointer w-full text-text-gray hover:text-blue-200 hover:bg-[#F5F8FF] rounded-lg flex gap-2 items-center">
                          <span className="text-sm text-gray-150">
                            {option?.label}
                          </span>
                        </div>
                      </div>
                      <div className="border-b border-border-gray pb-1.5" />
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </>
            )}
          </div>
        </>
      )}
    </Listbox>
  );
};

export default CategorySelect;
