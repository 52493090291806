import { useEffect, useState } from "react";
import { useAuthPasswordLess } from "../../contexts/AuthPasswordLessContext";
import {
  Arrow135,
  Discount,
  LeftArrow,
  Pencil,
  PlusIcon,
  ProfileIcon,
  TickMark,
} from "../../icons/icons";
import Header from "../common/Header";
import CategorySelect from "../common/offersOverview/CategorySelect";
import {
  OfferDroupDown,
  blockchainMappings,
  categoryOption,
} from "../../utils/constant";
import CustomeSelect from "../common/offersOverview/CustomeSelect";
import { calcuateDateDifference } from "../../utils/helper";
import { useHistory } from "react-router-dom";
import PauseOfferModal from "./PauseOfferModal";
import VerifyOffer from "../Offer/VerifyOffer";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import LoginFlowCompanyInfoModal from "../Overview/LoginFlowCompanyInfoModal";

const OffersOverview = () => {
  const {
    currentUser,
    offerData,
    GetClientOffers,
    pauseOfferModal,
    setOpenPauseOfferModal,
    pauseOfferLoading,
    UpdateOfferStatus,
    selectedId,
    setSelectedId,
    storeGetClientData,
  } = useAuthPasswordLess();
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedAction, setSelectedAction] = useState(null);
  const [showNextMap, setShowNextMap] = useState({}); // State to manage showNext for each row
  const [filteredData, setFilteredData] = useState(null); // State to store filtered data
  // eslint-disable-next-line no-unused-vars
  const [updateOffer, setUpdateOffer] = useState(false);
  const [openVerifyScreen, setOpenVerifyScreen] = useState(false);
  const [verifyData, storeVerifyData] = useState({
    offerId: null,
    totalParticipant: null,
    rewardAmount: null,
    totalDeposit: null,
  });
  const [copyText, setCopyText] = useState("Copy Link");

  const history = useHistory();
  const [OpenLoginFlowCompanyInfoModal, setOpenLoginFlowCompanyInfoModal] =
    useState(false);

  const formatCompanyName =
    storeGetClientData && storeGetClientData?.companyName?.replaceAll(" ", "-");

  const toggleShowNext = (rowId) => {
    setShowNextMap((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId],
    }));
  };
  const loginPath = localStorage.getItem("path");

  useEffect(() => {
    if (
      storeGetClientData?.companyName === null ||
      storeGetClientData === null
    ) {
      if (
        loginPath?.slice(1) === "welcome" ||
        loginPath?.slice(1) === "partners/login"
      ) {
        setOpenLoginFlowCompanyInfoModal(true);
      }
    }
  }, [storeGetClientData?.companyName, storeGetClientData]);

  useEffect(() => {
    if (storeGetClientData?.companyName) {
      GetClientOffers();
    }
  }, [storeGetClientData?.companyName]);
  useEffect(() => {
    if (selectedAction === "pause" && selectedId) {
      setOpenPauseOfferModal(true);
    } else if (selectedAction === "suspend" && selectedId) {
      UpdateOfferStatus(selectedId, "expired");
    } else if (selectedAction === "del") {
      DeleteOffer(selectedId);
    }
  }, [selectedAction, selectedId]);

  useEffect(() => {
    if (!currentUser) {
      history.push("/welcome");
    }
  }, []);

  useEffect(() => {
    if (offerData) {
      if (selectedCategory === "all") {
        setFilteredData(offerData);
      } else {
        const filteredOffers = offerData.filter(
          (offer) => offer?.offerTag?.toLowerCase() === selectedCategory
        );
        setFilteredData(filteredOffers);
      }
    }
  }, [offerData, selectedCategory]);

  const handleCopyText = () => {
    navigator.clipboard.writeText(
      `app.linkko.io/cashback/${formatCompanyName}`
    );
    setCopyText("Copied!");
    setTimeout(() => {
      setCopyText("Copy Link");
    }, 5000);
  };
  const sessionJwtToken = localStorage.getItem("sessionJwtToken");

  // Api for delete offer
  const DeleteOffer = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/delete_offers`,
        {
          method: "DELETE",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Authorization: sessionJwtToken,
          },
          body: JSON.stringify({
            client_id: storeGetClientData?.clientId,
            offer_id: id,
          }),
          redirect: "follow",
          referrerPolicy: "no-referrer",
        }
      );
      const { success } = await response.json();
      if (success) {
        GetClientOffers();
      }
      setSelectedAction(null);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {!openVerifyScreen ? (
        <>
          <div className="flex items-center justify-between">
            <div className="flex gap-2 items-start">
              <div className="text-primary-blue p-2 rounded-lg bg-light-blue w-fit">
                <Discount />
              </div>
              <div className="flex flex-col gap-2">
                <span className="text-base font-bold">Offers Overview</span>
                <span className="text-xs text-secondary-gray">
                  List of your live offer{" "}
                </span>
              </div>
            </div>
            <Header userEmail={currentUser?.email} />
          </div>
          <div className="mt-8 bg-light-blue rounded-3xl px-6 py-5 relative">
            <div className="flex items-center">
              <div className="w-full">
                <span className="text-3.25 font-bold">🚀 Share your Space</span>
                <div
                  className="p-px relative bg-primary-gradient rounded-full mt-4 max-w-[417px]"
                  onClick={handleCopyText}
                >
                  <div className=" bg-white  py-6 px-3 rounded-full overflow-hidden">
                    <span className="text-gray-150 text-3.25 top-4 absolute max-w-[calc(100%-_110px)] truncate overflow-hidden  white-space-nowrap">
                      {`app.linkko.io/cashback/${formatCompanyName}`}
                    </span>
                    <div className="px-3 py-2 absolute right-1.5 top-[7px] bg-primary-gradient rounded-3xl cursor-pointer">
                      <div className="flex items-center gap-1">
                        {copyText === "Copied!" && (
                          <TickMark className="text-white" />
                        )}
                        <span className="text-3.25 font-medium text-white">
                          {copyText}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="z-20 flex gap-4">
                <a
                  href={`https://app.linkko.io/cashback/${formatCompanyName}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="px-10 py-4 bg-white text-primary-blue whitespace-nowrap text-3.25 rounded-full z-20 flex items-center gap-2">
                    See page
                    <Arrow135 />
                  </button>
                </a>
                <Link to="/settings">
                  <button className="px-10 py-4 bg-white text-primary-blue whitespace-nowrap text-3.25 rounded-full z-20 flex items-center gap-2">
                    <Pencil />
                    Edit info
                  </button>
                </Link>
              </div>
            </div>

            <img
              src="/assets/images/middle.svg"
              alt="middle bg"
              className="absolute bottom-0 right-0 h-full"
            />
          </div>
          {offerData?.length >= 1 ? (
            <>
              <div className="w-fit mt-8">
                <CategorySelect
                  options={categoryOption}
                  value={selectedCategory}
                  onChange={(value) => {
                    setSelectedCategory(value);
                  }}
                />
              </div>
              {filteredData?.map((data, index) => (
                <div
                  className="border border-primary-blue border-opacity-20 rounded-2xl p-4 mt-6"
                  key={index}
                >
                  <div className="flex justify-between">
                    <div className="flex items-center gap-6">
                      <div className="w-8 h-8 rounded-full items-center flex justify-center bg-sky-50 relative">
                        <img
                          src={data?.offerClient?.clientLogo}
                          alt="logo"
                          className="object-contain rounded-full"
                        />

                        <div className="bg-primary-gradient p-px absolute -bottom-0 -right-1 rounded-full group">
                          <div className=" bg-white w-4 h-4 rounded-full flex justify-center items-center">
                            <img
                              src={`/assets/icons/blockchainIcons/${
                                blockchainMappings[
                                  data?.offerRequirements.blockchain
                                ]?.imgSrc
                              }`}
                              alt=""
                              className="w-3 h-3"
                            />

                            <div className="group-hover:visible invisible absolute text-white bg-black-100 rounded-lg top-5 px-2 py-1 text-[11px]">
                              {
                                blockchainMappings[
                                  data?.offerRequirements.blockchain
                                ]?.displayName
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center gap-4">
                        <span className="text-base capitalize">
                          {data?.offerType}
                        </span>
                        <div className="flex gap-1 items-center group">
                          <img
                            src={data?.offerRequirements?.tokenImage}
                            alt="logo"
                            className="object-contain rounded-full w-4 h-4"
                          />
                          <span className="font-bold">
                            {data?.offerRequirements?.requiredAmount}{" "}
                            {data?.offerRequirements?.tokenName}
                          </span>
                          {/* <div className="text-primary-blue group-hover:scale-150">
                    <Arrow135 />
                  </div> */}
                        </div>
                        <span>on</span>

                        <div className="flex gap-1 items-center group">
                          <img
                            src={`/assets/icons/blockchainIcons/${
                              blockchainMappings[
                                data?.offerRequirements.blockchain
                              ]?.imgSrc
                            }`}
                            alt="logo"
                            className="object-contain w-4 h-4"
                          />
                          <span className="font-bold">
                            {" "}
                            {
                              blockchainMappings[
                                data?.offerRequirements.blockchain
                              ]?.displayName
                            }
                          </span>
                          <div className="text-primary-blue group-hover:scale-150">
                            {/* <Arrow135 /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-2 items-center">
                      {data.offerStatus === "draft" ? (
                        <button
                          className="bg-primary-gradient py-3 px-5 rounded-full text-3.25 text-white"
                          onClick={() => {
                            storeVerifyData((pre) => ({
                              ...pre,
                              offerId: data?.offerId,
                              rewardAmount: data?.offerReward,
                              totalParticipant: data?.offerParticipants,
                              totalDeposit:
                                data?.offerParticipants *
                                data?.offerReward *
                                1.2,
                            }));
                            setOpenVerifyScreen(true);
                          }}
                        >
                          Deposit and Launch
                        </button>
                      ) : (
                        <button
                          className="text-primary-blue text-3.25 flex items-center gap-1 py-3 px-6 bg-light-blue rounded-full"
                          onClick={() => {
                            history.push(`/offers-overview/${data?.offerId}`);
                          }}
                        >
                          <span>Check Analysis</span>
                          <Arrow135 />
                        </button>
                      )}
                      <div className="px-1 cursor-pointer">
                        <CustomeSelect
                          options={
                            data?.offerStatus === "draft"
                              ? OfferDroupDown
                              : data?.offerStatus === "live"
                              ? OfferDroupDown.slice(0, 2)
                              : [OfferDroupDown[1]]
                          }
                          value={selectedAction}
                          onChange={(value) => {
                            setSelectedId(data?.offerId);
                            setSelectedAction(value);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="border-b border-light-blue my-4" />
                    <div className="flex gap-10">
                      <div className="flex flex-col gap-2 w-24">
                        <span className="text-gray-150 text-xs">Status</span>
                        <span
                          className={`px-6 py-1  ${
                            data?.offerStatus === "live"
                              ? "bg-green-100 text-green-200"
                              : data?.offerStatus === "draft"
                              ? "text-yellow-200 bg-yellow-100"
                              : data?.offerStatus === "expired"
                              ? "bg-bright-gray text-gray-150"
                              : "bg-red-100 text-red-200"
                          } rounded-full w-fit text-3.25 capitalize`}
                        >
                          {data?.offerStatus}
                        </span>
                      </div>
                      <div className="flex flex-col gap-2">
                        <span className="text-gray-150 text-xs">Category</span>
                        <span className="text-sm w-[70px] truncate">
                          {data?.offerTag}
                        </span>
                      </div>
                      <div className="flex flex-col gap-2">
                        <span className="text-gray-150 text-xs">Reward </span>
                        <div>
                          <div className="flex gap-2 items-center text-primary-blue">
                            <img
                              src={data?.offerRewardTokenImage}
                              alt="reward token"
                              className="w-4 h-4 "
                            />
                            <span className="text-sm text-black w-20 truncate">
                              {data?.offerReward} {data?.offerRewardTokenName}
                            </span>
                          </div>{" "}
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <span className="text-gray-150 text-xs">Time</span>
                        <span className=" text-sm w-32 truncate">
                          {" "}
                          {data.offerExpirationDate
                            ? calcuateDateDifference(data.offerExpirationDate)
                            : "unlimited"}
                        </span>
                      </div>
                      <div className="my-auto">
                        {data.offerTotalReward && data.offerParticipants ? (
                          <>
                            <div className="w-fit">
                              <div className="flex gap-2 items-center">
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    toggleShowNext(data.offerId);
                                  }}
                                >
                                  <LeftArrow />
                                </button>

                                {showNextMap[data.offerId] ?? false ? (
                                  <div className="flex gap-2 items-center">
                                    <img
                                      src={data.offerRewardTokenImage}
                                      alt=""
                                      className="w-4 h-4 object-contain"
                                    />
                                    <span className="text-xs text-gray-150 font-medium w-32 truncate ">
                                      {data.distributedReward} /{" "}
                                      {data.offerTotalReward}{" "}
                                      {data.offerRewardTokenName}
                                    </span>
                                  </div>
                                ) : (
                                  <div className="flex gap-2 items-center">
                                    <ProfileIcon className="text-primary-blue w-4 h-4" />
                                    <span className="text-xs text-gray-150 font-medium w-32 truncate">
                                      {data.currentEntry} /{" "}
                                      {data.offerParticipants} Participants
                                    </span>{" "}
                                  </div>
                                )}
                                <button
                                  className="rotate-180"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    toggleShowNext(data.offerId);
                                  }}
                                >
                                  <LeftArrow />
                                </button>
                              </div>

                              <div className="py-1 mt-2">
                                <div className="h-0.5 md:h-1.5 w-[170px] bg-blue-100 rounded-full overflow-hidden mx-auto">
                                  <div
                                    className="h-full bg-primary-gradient rounded-full"
                                    style={{
                                      width: `${
                                        showNextMap[data.offerId] ?? false
                                          ? (data.distributedReward /
                                              data.offerTotalReward) *
                                            100
                                          : (data.currentEntry /
                                              data.offerParticipants) *
                                            100
                                      }%`,
                                      transition: "width 1s linear",
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {data.offerParticipants ? (
                              <>
                                <div className="flex gap-2 items-center">
                                  <span className="text-xs text-gray-150 font-medium ">
                                    {data.currentEntry} /{" "}
                                    {data.offerParticipants} Participants
                                  </span>
                                </div>
                                <div className="py-1 w-full mt-2">
                                  <div className="h-0.5 md:h-1.5 w-[180px] bg-blue-100 rounded-full overflow-hidden">
                                    <div
                                      className="h-full bg-primary-gradient rounded-full"
                                      style={{
                                        width: `${
                                          (data.currentEntry /
                                            data.offerParticipants) *
                                          100
                                        }%`,
                                        transition: "width 1s linear",
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </>
                            ) : data.offerTotalReward ? (
                              <>
                                <div className="flex gap-2 items-center">
                                  <img
                                    src={data.offerRewardTokenImage}
                                    alt=""
                                    className="w-4 h-4 object-contain"
                                  />

                                  <span className="text-xs text-gray-150 font-medium ">
                                    {data.distributedReward} /{" "}
                                    {data.offerTotalReward}{" "}
                                    {data.offerRewardTokenName}
                                  </span>
                                </div>
                                <div className="py-1 w-full mt-2">
                                  <div className="h-0.5 md:h-1.5 w-[180px] bg-blue-100 rounded-full overflow-hidden">
                                    <div
                                      className="h-full bg-primary-gradient rounded-full"
                                      style={{
                                        width: `${
                                          (data.distributedReward /
                                            data.offerTotalReward) *
                                          100
                                        }%`,
                                        transition: "width 1s linear",
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <span className="text-sm font-medium">
                                unlimited
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mx-auto w-full flex flex-col gap-1 justify-center items-center">
              <span className="text-base text-secondary-gray">
                It looks like you haven't created any offers lists yet.
              </span>
              <span className="text-base text-secondary-gray">
                Click "
                <span className="text-primary-blue font-semibold">
                  Create a new Offer
                </span>
                ” button to create a new one and start showcasing your offers.
              </span>
              <button
                className="bg-primary-gradient flex gap-2 items-center px-8 py-3 rounded-2xl mt-3"
                onClick={() => history.push("/new-offer")}
              >
                <PlusIcon />
                <div className="text-3.25 bg-primary-gradient text-transparent bg-clip-text text-white font-semibold text-base">
                  Create a new Offer
                </div>
              </button>
            </div>
          )}
          <div className="pb-10" />
          <PauseOfferModal
            open={pauseOfferModal}
            onClose={() => {
              setOpenPauseOfferModal(false);
              setSelectedAction(null);
              setSelectedId(null);
            }}
            pauseOfferLoading={pauseOfferLoading}
            selectedId={selectedId}
          />
          <LoginFlowCompanyInfoModal
            open={OpenLoginFlowCompanyInfoModal}
            setOpenLoginFlowCompanyInfoModal={setOpenLoginFlowCompanyInfoModal}
          />
        </>
      ) : (
        <VerifyOffer
          setOpenVerifyScreen={setOpenVerifyScreen}
          setUpdateOffer={setUpdateOffer}
          totalDeposit={verifyData?.totalDeposit?.toLocaleString("en-US")}
          rewardAmount={verifyData?.rewardAmount}
          totalParticipant={verifyData?.totalParticipant}
          offerId={verifyData?.offerId}
          isVisible={false}
        />
      )}
    </>
  );
};
export default OffersOverview;
