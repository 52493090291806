export const isUrlValid = (userInput) => {
  var res = userInput?.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );
  if (res == null) return false;
  else return true;
};

// To conver 2023-10-25T11:42:07.755283 time format to 11:38 AM
export const convertDateToTime = (input_Date) => {
  const date = new Date(input_Date);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amOrPm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedTime = `${formattedHours}:${formattedMinutes} ${amOrPm}`;
  return formattedTime;
};

//check number is grater than 1 or amd not a decimal value
export const checkIsValidNumber = (number) => {
  return number < 1 || number % 1 !== 0;
};

export const formateNum = (number) => {
  return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const calcuateDateDifference = (date) => {
  const now = new Date();
  const expiration = new Date(date);
  const timeDifference = expiration - now;

  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const months = Math.floor(days / 30);
  const remainingDays = Math.floor(days) - 30 * months;

  let result = "";

  if (months > 0) {
    result += months + (months === 1 ? " month " : " months ");
    if (remainingDays >= 1) {
      result +=
        remainingDays === 1 ? `${remainingDays} day` : `${remainingDays} days`;
    }
  } else {
    if (days > 0) {
      result += days + (days === 1 ? " day " : " days ");
      if (hours > 0) {
        result += hours + (hours === 1 ? " hour " : " hours ");
      }
    } else if (hours > 0) {
      result += hours + (hours === 1 ? " hour " : " hours ");
      if (minutes > 0) {
        result += minutes + (minutes === 1 ? " minute" : " minutes");
      }
    } else if (minutes > 0) {
      result += minutes + (minutes === 1 ? " minute" : " minutes");
    }
  }

  return result.trim();
};
