import classNames from "classnames";
import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { useAuthPasswordLess } from "../../contexts/AuthPasswordLessContext";

const Header = (props) => {
  const { userEmail } = props || {};
  const [profileOptionsVisible, setProfileOptionsVisible] = useState(false);
  const history = useHistory();
  const { handleLogoutCall } = useAuthPasswordLess();

  async function handleLogout(e) {
    e.preventDefault();
    try {
      await handleLogoutCall();
      history.push("/welcome");
    } catch {}
  }

  return (
    <header className="header flex justify-end items-end">
      <div
        className="cursor-pointer relative w-max select-none"
        onClick={(e) => {
          e.preventDefault();
          setProfileOptionsVisible(!profileOptionsVisible);
        }}
      >
        <span className="flex gap-4 items-center  text-text-gray leading-5">
          {userEmail}
          <img
            src={"/assets/icons/caret-down.svg"}
            alt="Profile"
            className={profileOptionsVisible && "rotate-180"}
          />
        </span>
        <div
          className={classNames(
            "absolute right-0 top-6 z-[999]",
            profileOptionsVisible ? "block" : "hidden"
          )}
        >
          <button
            className="flex gap-4 mt-2 items-center bg-white text-red-500 text-sm font-medium w-max rounded-lg shadow-lg cursor-pointer py-2.5 px-5"
            onClick={handleLogout}
          >
            <img src="/assets/icons/logout.svg" alt="" />
            <span>Logout</span>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
