import {
  ArrowsightLeftFill,
  Calender,
  Category,
  DollarSquare,
  Profile,
  LoaderIcon,
} from "../../icons/icons";
import {
  DEXData,
  inputBlackchainOptions,
  blockchainLabels,
} from "../../utils/constant";
import { useAuthPasswordLess } from "../../contexts/AuthPasswordLessContext";

const PreviewComponent = ({
  selectedCategory,
  selectedOption,
  selectedBlockchain,
  duration,
  addedAmount,
  rewardAmount,
  totalParticipant,
  totalDeposit,
  startDuration,
  selectedDate,
  selectedEndDate,
  handleRequireFieldCheck,
  isRequiredEmpty,
  tokenInfoData,
  publishOfferLoading,
  selectedDEX,
  setOpenOfferPreview,
  endDuration,
}) => {
  const selected = inputBlackchainOptions?.find(
    (option) => option?.value === selectedBlockchain?.toLowerCase()
  );

  const { currentUser } = useAuthPasswordLess();

  const formatDate = (date) => {
    const month = date?.toLocaleDateString("en-US", { month: "short" });
    const day = date?.getDate()?.toString()?.padStart(2, "0"); // Add leading zero if needed
    const year = date?.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const dateShow = selectedDate && formatDate(selectedDate);
  const endDate = selectedEndDate && formatDate(selectedEndDate);

  const findDEXLogo = DEXData?.find((data) => data?.label === selectedDEX);

  return (
    <>
      <div className="max-w-[440px] w-full">
        <div className="p-px bg-primary-gradient h-fit rounded-3xl sticky top-[80px]">
          <div className="flex bg-white rounded-3xl p-5 xl:p-10 flex-col">
            <span className="font-semibold text-base">Detail Overview</span>
            <div className="flex gap-2 flex-col mt-6">
              <span className="text-text-gray text-3.25">Category</span>
              <div className="h-5">
                {selectedCategory && (
                  <div className="flex gap-2 items-center">
                    <Category width={20} height={20} color="#5E91FF" />
                    <span className="text-sm font-semibold">
                      {selectedCategory}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="flex gap-2 flex-col mt-6">
              <span className="text-text-gray text-3.25">Action</span>

              {addedAmount || selectedOption ? (
                <>
                  <div className="flex gap-2 items-center flex-wrap">
                    {selectedOption && (
                      <>
                        <ArrowsightLeftFill className="text-primary-blue" />
                        <span className="text-sm font-semibold capitalize">
                          {selectedOption === "swap specific token"
                            ? "swap"
                            : selectedOption}
                        </span>
                      </>
                    )}

                    {addedAmount && selectedOption !== "swap any" && (
                      <>
                        <span className="text-sm font-semibold">
                          {selectedOption !== "list" && addedAmount}{" "}
                          {selectedOption === "stake/liquid stake"
                            ? blockchainLabels[selectedBlockchain]?.labelName
                            : tokenInfoData?.name
                            ? tokenInfoData?.name
                            : tokenInfoData &&
                              tokenInfoData[0] &&
                              tokenInfoData[0]?.token}
                        </span>
                      </>
                    )}

                    {selectedCategory !== "🔗 Offchain" && (
                      <>
                        {selectedOption !== "swap any" &&
                          selectedOption !== "swap specific token" && (
                            <>
                              <span className="text-sm font-semibold">on</span>
                              <img
                                src={`/assets/icons/${selected?.logo}`}
                                alt=""
                                className="w-4 h-4"
                              />
                              <span className="text-sm font-semibold">
                                {selectedBlockchain}
                              </span>
                            </>
                          )}
                        {selectedCategory === "🤝 DEX" && (
                          <>
                            <span className="text-sm font-semibold">on</span>
                            <img
                              src={`/assets/icons/${findDEXLogo?.logo}`}
                              alt=""
                              className="w-4 h-4"
                            />
                            <span className="text-sm font-semibold">
                              {selectedDEX}
                            </span>
                            {selectedOption === "swap any" && addedAmount && (
                              <>
                                <span className="text-sm font-semibold">
                                  for
                                </span>
                                <span className="text-sm font-semibold">
                                  {addedAmount} USD
                                </span>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {selectedOption === "list" && (
                      <>
                        <span className="text-sm font-semibold">for</span>
                        <span className="text-sm font-semibold">
                          {addedAmount} ETH
                        </span>
                      </>
                    )}
                    {(selectedOption === "hold" ||
                      selectedOption === "deposit liquidity" ||
                      selectedOption === "list") && (
                      <>
                        <span className="text-sm font-semibold">for</span>
                        {duration && (
                          <>
                            <span className="text-sm font-semibold">
                              {duration}
                            </span>
                            <span className="text-sm font-semibold">days</span>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </>
              ) : (
                <div className="h-5"></div>
              )}
            </div>
            <div className="flex gap-2 flex-col mt-6">
              <span className="text-text-gray text-3.25">
                Reward & participant
              </span>
              {rewardAmount || totalParticipant ? (
                <div className="flex gap-4 items-center flex-wrap">
                  {rewardAmount && (
                    <div className="flex gap-2 items-center text-primary-blue">
                      <DollarSquare width={20} height={20} />
                      <span className="text-sm font-semibold text-black">
                        $ {rewardAmount}
                      </span>
                      <img src="/assets/icons/dollar.svg" alt="" />
                      <span className="text-sm font-semibold text-black">
                        USDC
                      </span>
                    </div>
                  )}
                  {totalParticipant && (
                    <div className="flex gap-2 items-center">
                      <Profile />
                      <span className="text-sm font-semibold">
                        {totalParticipant}
                      </span>
                      <span className="text-sm font-semibold text-black">
                        {totalParticipant > 1 ? "Participants" : "Participant"}
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <div className="h-5"></div>
              )}
            </div>

            <div className="flex gap-2 flex-col mt-6">
              <span className="text-text-gray text-3.25">Total budget</span>
              {rewardAmount && totalParticipant ? (
                <div className="flex gap-2 items-center text-primary-blue">
                  <DollarSquare width={20} height={20} />
                  <span className="text-sm font-semibold text-black">
                    $ {totalDeposit}
                  </span>
                  <img src="/assets/icons/dollar.svg" alt="" />
                  <span className="text-sm font-semibold text-black">USDC</span>
                </div>
              ) : (
                <div className="h-5"></div>
              )}
            </div>
            <div className="flex gap-2 flex-col mt-6">
              <span className="text-text-gray text-3.25">Duration</span>
              {dateShow || endDate ? (
                <div className="flex gap-2 items-center text-primary-blue">
                  <Calender />
                  <span className="text-sm font-semibold text-black">
                    {`${dateShow ? dateShow : ""} ${
                      selectedEndDate
                        ? `- ${endDate}`
                        : endDuration === "unlimited"
                        ? " - unlimited"
                        : ""
                    }`}
                  </span>
                </div>
              ) : (
                <div className="h-5"></div>
              )}
            </div>
            <div className="mt-10 flex gap-4 w-full justify-center flex-col xl:flex-row">
              <button
                onClick={() => setOpenOfferPreview(true)}
                className="py-4 w-full  bg-light-blue text-primary-blue rounded-full text-3.25 hover:shadow-primaryShadow"
              >
                Preview
              </button>
              <button
                className="bg-primary-gradient text-white text-3.25 w-full rounded-full hover:shadow-primaryShadow py-4"
                onClick={handleRequireFieldCheck}
                disabled={publishOfferLoading}
              >
                {publishOfferLoading ? (
                  <div className="flex items-center justify-center animate-spin ">
                    <LoaderIcon />
                  </div>
                ) : (
                  "🚀 Save & Deposit"
                )}
              </button>
            </div>
            <span className="mt-5 text-primary-blue text-3.25 text-center hover:underline underline-offset-2  ">
              <a
                href={`https://linkko.typeform.com/to/GiMoVt56#email=${currentUser?.email}&uid=${currentUser?.uid}&from=deposit`}
                target="_blank"
                rel="noreferrer"
              >
                <span>Need help? Have a suggestion? Let us know here!</span>
              </a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default PreviewComponent;
