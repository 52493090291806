import React from "react";

const MobileVectors = () => {
  return (
    <div className="md:hidden">
      <img
        src="/assets/images/login-mobile-vector-1.svg"
        className="absolute top-0 right-0 opacity-10"
        alt="login-2"
      />
      <img
        src="/assets/images/login-mobile-vector-2.svg"
        className="absolute bottom-0 -left-5"
        alt="login"
      />
    </div>
  );
};

export default MobileVectors;
