import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { TickMark } from "../../icons/icons";
import Error from "../common/offerComponents/Error";
import { tags } from "../../utils/constant";

export default function TagList({ selected, setSelected }) {
  const toggleSelection = (person) => {
    const isSelected = selected?.some((p) => p === person);

    if (isSelected) {
      // If already selected, deselect it
      setSelected((prev) => prev?.filter((p) => p !== person));
    } else if (selected?.length < 3) {
      // If not selected and less than 3 items, select it
      setSelected((prev) => [...prev, person]);
    }
  };

  return (
    <div>
      <Listbox value={selected} multiple>
        <div className="relative mt-1">
          <Listbox.Button className="border border-border-gray py-3 px-6 text-left w-full rounded-lg mt-2 text-sm flex items-center justify-between focus:border-primary-blue focus:border-opacity-25">
            <span className="block truncate">
              {" "}
              {selected?.length === 0
                ? "Select Tags"
                : selected?.map((s) => s).join(", ")}
            </span>
            <img src="/assets/icons/caret-down.svg" alt="caret" />
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-56 w-full overflow-auto rounded-lg bg-white py-4 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {tags?.map((person, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    ` cursor-default select-none py-2 px-8 flex gap-2 items-center ${
                      active ? "bg-sky-50 " : "text-gray-150"
                    }`
                  }
                  value={person}
                  onClick={() => toggleSelection(person)}
                >
                  <>
                    <div className="relative w-6 h-6 border border-primary-blue rounded-lg border-opacity-50 ">
                      {selected?.some((p) => p === person) ? (
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-primary-blue bg-light-blue">
                          {" "}
                          <TickMark />{" "}
                        </div>
                      ) : null}
                      <span className=""></span>
                    </div>
                    <span
                      className={`block truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      {person}
                    </span>
                  </>
                </Listbox.Option>
              ))}
              {selected?.length >= 3 ? (
                <div className="px-5">
                  <Error message={"You can only select up to 3 max tags"} />
                </div>
              ) : (
                ""
              )}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
