import React, { useEffect } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";

import Sidebar from "./Sidebar/Sidebar";
// import NewConfiguration from "./NewConfiguration/NewConfiguration";
// import ConnectApp from "./NewConfiguration/ConnectApp/ConnectApp";
// import EditConfiguration from "./EditConfiguration/EditConfiguration";
// import DiscordAuth from "./Auth/DiscordAuth/DiscordAuth";
import { useState } from "react";
import Options from "./Auth/Options";
import VisitSite from "./Auth/VisitSite";
import useMediaQuery from "../utils/useMediaQuery";

// import Analytics from "./Analytics/Analytics";
import LoginMail from "./Auth/LoginMail";
import { AuthPasswordLessProvider } from "../contexts/AuthPasswordLessContext";
import ValidateOTP from "./Auth/ValidateOTP";
// import Overview from "./Overview/Overview";
// import NewCampaign from "./CreateNewCampaign/NewCampaign";
// import AdsReporting from "./Overview/AdsReporting";
import SignUp from "./Auth/SignUp";
import ParternerLogin from "./Auth/ParternerLogin";
import CreateNewOffer from "./Offer/CreateNewOffer";
import OffersOverview from "./OffersOverview/Overview";
import OfferAnalytics from "./OffersOverview/OfferAnalytics";
import Settings from "./Setting/Setting";

const withoutSidebar = [
  "/welcome",
  "/options",
  "/visit-site",
  "/onboarding",
  "/validate",
  "/sign-up",
  "/partners/login",
];

function App() {
  const { pathname } = useLocation();
  const [hasSidebar, setHasSidebar] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    setHasSidebar(!withoutSidebar.includes(pathname));
    if (pathname?.includes("/welcome")) {
      setHasSidebar(false);
    }
  }, [pathname]);

  return (
    <AuthPasswordLessProvider>
      {hasSidebar && !isMobile && <Sidebar />}
      <main
        className={`${hasSidebar && !isMobile && "ml-[190px] p-10 h-screen"}`}
      >
        <Switch>
          <Route exact path="/sign-up" component={SignUp} />
          <Route exact path="/welcome" component={LoginMail} />
          <Route exact path="/welcome/:id" component={LoginMail} />
          <Route exact path="/validate" component={ValidateOTP} />
          {/* <Route exact path="/new-campaign" component={NewCampaign} /> */}
          <Route exact path="/options" component={Options} />
          <Route exact path="/visit-site" component={VisitSite} />
          <Route exact path="/partners/login" component={ParternerLogin} />
          {/* <Route exact path="/campaign/:capaignId" component={AdsReporting} /> */}
          <Route exact path="/new-offer" component={CreateNewOffer} />
          <Route exact path="/offers-overview" component={OffersOverview} />
          <Route
            exact
            path="/offers-overview/:offerId"
            component={OfferAnalytics}
          />
          <Route exact path="/settings" component={Settings} />

          {/* <Route exact path="/campaign-overview">
            <Overview />
          </Route> */}

          <Route path="*">
            <Redirect to="/offers-overview" />
          </Route>
        </Switch>
      </main>
    </AuthPasswordLessProvider>
  );
}

export default App;
