const CustomeFloatAmountInput = ({
  setState,
  state,
  placeholder,
  labelName,
  isDevided,
  isRequiredEmpty,
  errorMessge,
  tokenInfoLoading,
}) => {
  const handleInputChange = (e) => {
    const value = e.target.value;
    setState(value);
  };

  const preventNegative = (e) => {
    if (e.key === "-") {
      e.preventDefault();
    }
  };

  return (
    <>
      <div
        className={`border ${
          isRequiredEmpty && !state
            ? "border-red-500"
            : `${errorMessge ? "border-red-200" : "border-border-gray"}`
        } rounded-2xl max-w-[480px]`}
      >
        <div className="px-6 py-2.5 flex justify-between">
          <div className="flex w-full">
            <input
              type={"number"}
              className=" w-full text-start border-transparent focus:ring-0 focus:border-transparent text-sm  placeholder:text-secondary-gray"
              placeholder={placeholder}
              value={state}
              onChange={handleInputChange}
              onKeyDown={preventNegative}
              onWheel={(e) => e.target.blur()}
              min="0"
            />
            {isDevided && <div className="border-r border-r-gray-300 mx-6" />}
          </div>
          <div className="flex gap-2 items-center w-20">
            {/* {logoPath && <img src={`/assets/icons/${logoPath}`} alt="logo" />} */}
            {tokenInfoLoading ? (
              <div className="mx-auto">
                <img
                  src="/assets/icons/loading.svg"
                  alt="loading"
                  className="animate-spin"
                />
              </div>
            ) : (
              <span className="text-sm font-bold truncate">{labelName}</span>
            )}
          </div>
        </div>
      </div>
      {errorMessge && (
        <span className="text-red-200 text-xs">{errorMessge}</span>
      )}
    </>
  );
};
export default CustomeFloatAmountInput;
