import { Listbox } from "@headlessui/react";
import { DotMenu } from "../../../icons/icons";

const CustomeSelect = ({ options, value, onChange }) => {
  return (
    <Listbox as="div" value={value} onChange={onChange}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button className={`my-auto`}>
              <DotMenu />
            </Listbox.Button>
            {open && (
              <>
                <Listbox.Options
                  static
                  className="absolute right-0 top-10 w-52 mt-1 p-4 bg-white shadow-lg rounded-md z-10 mx-0"
                >
                  {options?.map((option, index) => {
                    const isLastItem = index === options.length - 1;

                    return (
                      <Listbox.Option key={option?.value} value={option.value}>
                        <div className="flex items-center pt-1.5">
                          <div
                            className={`py-2.5 p-4 cursor-pointer w-full  ${
                              option?.value === "pause"
                                ? "hover:bg-[#FFF7E6] hover:text-[#FCAF03] text-gray-150"
                                : "hover:bg-[#FFF4F4] hover:text-[#FF5B4C] text-gray-150"
                            } rounded-lg flex gap-2 items-center`}
                          >
                            <span className="text-sm">{option?.label}</span>
                          </div>
                        </div>
                        {!isLastItem && (
                          <div className="border-b border-border-gray pb-1.5" />
                        )}
                      </Listbox.Option>
                    );
                  })}
                </Listbox.Options>
              </>
            )}
          </div>
        </>
      )}
    </Listbox>
  );
};

export default CustomeSelect;
