import { useLocation } from "react-router-dom";
import { useAuthPasswordLess } from "../../contexts/AuthPasswordLessContext";
import { OfferDroupDown, blockchainMappings } from "../../utils/constant";
import {
  BillIcon,
  DollarSquare,
  EyeIcon,
  LeftArrow,
  LikeThumb,
  ProfileIcon,
  ResumeOutline,
  RoundedClock,
  TrendUp,
} from "../../icons/icons";
import { calcuateDateDifference, formateNum } from "../../utils/helper";
import { useEffect, useState } from "react";
import Header from "../common/Header";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomeSelect from "../common/offersOverview/CustomeSelect";
import PauseOfferModal from "./PauseOfferModal";

const OfferAnalytics = () => {
  const {
    offerData,
    currentUser,
    GetClientOffers,
    pauseOfferModal,
    setOpenPauseOfferModal,
    pauseOfferLoading,
    UpdateOfferStatus,
    selectedId,
    setSelectedId,
  } = useAuthPasswordLess();
  const { pathname } = useLocation();
  const history = useHistory();

  const segments = pathname.split("/");
  const lastSegment = segments.pop();
  const [showNextMap, setShowNextMap] = useState({}); // State to manage showNext for each row
  const [selectedAction, setSelectedAction] = useState(null);
  useEffect(() => {
    if (selectedAction === "pause" && selectedId) {
      setOpenPauseOfferModal(true);
    } else if (selectedAction === "suspend" && selectedId) {
      UpdateOfferStatus(selectedId, "expired");
    }
  }, [selectedAction]);

  const toggleShowNext = (rowId) => {
    setShowNextMap((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId],
    }));
  };
  const resultOffer = offerData?.find((obj) => obj.offerId === lastSegment);

  useEffect(() => {
    if (offerData?.length === 0) {
      GetClientOffers();
    }
  }, []);

  const SummaryDetails = [
    {
      title: "Number of views",
      iconComponent: <EyeIcon />,
      count: formateNum(resultOffer?.offerAnalytics?.numberOfViews),
    },
    {
      title: "Number of clicks",
      iconComponent: <LikeThumb />,
      count: formateNum(resultOffer?.offerAnalytics?.numberOfClicks),
    },
    {
      title: "Number of conversions",
      iconComponent: <TrendUp />,
      count: formateNum(resultOffer?.offerAnalytics?.numberOfConversions),
    },
    {
      title: "Reward distributed",
      iconComponent: <DollarSquare width={24} height={24} />,
      count: `${
        resultOffer?.offerAnalytics?.rewardDistributed === undefined
          ? ""
          : `$ ${formateNum(resultOffer?.offerAnalytics?.rewardDistributed)}`
      }`,
    },
    {
      title: "Completion rate",
      iconComponent: <TrendUp />,
      count: `${resultOffer?.offerAnalytics?.completionRate} %`,
    },
    {
      title: "Time remaining",
      iconComponent: (
        <RoundedClock color="currentColor" width={24} height={24} />
      ),
      count: resultOffer?.offerExpirationDate
        ? calcuateDateDifference(resultOffer.offerExpirationDate)
        : "unlimited",
    },
    {
      title: "Number started",
      iconComponent: <BillIcon />,
      count: resultOffer?.offerAnalytics?.numberStarted,
    },
    {
      title: "Budget locked",
      iconComponent: <DollarSquare width={24} height={24} />,
      count: `$ ${formateNum(resultOffer?.offerAnalytics?.budgetLocked)}`,
    },
    {
      title: "Budget avaliable",
      iconComponent: <DollarSquare width={24} height={24} />,
      count: `$ ${formateNum(resultOffer?.offerAnalytics?.budgetAvailable)}`,
    },
  ];

  return (
    <>
      <div className="flex justify-between items-center mb-5 xl:mb-10">
        <div
          onClick={() => history.push("/offers-overview")}
          className="flex gap-2 items-center cursor-pointer whitespace-nowrap"
        >
          <img src="/assets/icons/left_blue.svg" alt="back" />
          <span className="text-base font-semibold text-primary-blue">
            Back
          </span>
        </div>
        <Header userEmail={currentUser?.email} />
      </div>
      <div className="flex justify-between">
        <div className="flex items-center gap-6">
          <div className="h-10 w-10 xl:w-12 xl:h-12 rounded-full items-center flex justify-center bg-sky-50 relative">
            <img
              src={resultOffer?.offerClient?.clientLogo}
              alt="logo"
              className="object-contain rounded-full"
            />

            <div className="bg-primary-gradient p-px absolute -bottom-0 -right-1 rounded-full group">
              <div className=" bg-white w-4 h-4 xl:w-5 xl:h-5 rounded-full flex justify-center items-center">
                <img
                  src={`/assets/icons/blockchainIcons/${
                    blockchainMappings[
                      resultOffer?.offerRequirements.blockchain
                    ]?.imgSrc
                  }`}
                  alt=""
                  className="w-3 h-3"
                />

                <div className="group-hover:visible invisible absolute text-white bg-black-100 rounded-lg top-5 px-2 py-1 text-[11px]">
                  {
                    blockchainMappings[
                      resultOffer?.offerRequirements.blockchain
                    ]?.displayName
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <span className="text-lg xl:text-2xl capitalize">
              {resultOffer?.offerType}
            </span>
            <div className="flex gap-1 items-center group">
              <img
                src={resultOffer?.offerRequirements?.tokenImage}
                alt="logo"
                className="object-contain rounded-full w-4 h-4"
              />
              <span className="font-bold text-lg xl:text-2xl">
                {resultOffer?.offerRequirements?.requiredAmount}{" "}
                {resultOffer?.offerRequirements?.tokenName}
              </span>
              {/* <div className="text-primary-blue group-hover:scale-150">
                    <Arrow135 />
                  </div> */}
            </div>
            <span className="text-lg xl:text-2xl">on</span>

            <div className="flex gap-1 items-center group">
              <img
                src={`/assets/icons/blockchainIcons/${
                  blockchainMappings[resultOffer?.offerRequirements.blockchain]
                    ?.imgSrc
                }`}
                alt="logo"
                className="object-contain w-6 h-6"
              />
              <span className="font-bold text-lg xl:text-2xl">
                {" "}
                {
                  blockchainMappings[resultOffer?.offerRequirements.blockchain]
                    ?.displayName
                }
              </span>
              <div className="text-primary-blue group-hover:scale-150">
                {/* <Arrow135 /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2">
          {resultOffer?.offerStatus === "live" ||
            (resultOffer?.offerStatus === "expired" && (
              <button className="py-2 px-3.5 bg-primary-gradient rounded-full text-3.25 text-white h-fit my-auto flex gap-1 items-center">
                <DollarSquare width={16} height={16} />
                Withdraw Balance
              </button>
            ))}
          {resultOffer?.offerStatus === "pause" && (
            <button
              className="py-2 px-3.5 bg-primary-gradient rounded-full text-3.25 text-white h-fit my-auto flex gap-2 items-center"
              onClick={() => {
                UpdateOfferStatus(resultOffer?.offerId, "live");
              }}
            >
              <ResumeOutline />
              Resume Offer
            </button>
          )}
          <div className="px-1 cursor-pointer">
            <CustomeSelect
              options={
                resultOffer?.offerStatus === "live"
                  ? OfferDroupDown
                  : [OfferDroupDown[1]]
              }
              value={selectedAction}
              onChange={(value) => {
                setSelectedAction(value);
                setSelectedId(resultOffer?.offerId);
              }}
            />
          </div>
        </div>
      </div>
      <div className="mt-6 xl:mt-10 mb-4">
        <span className="text-base font-semibold">Offer Overview</span>
      </div>
      <div className="flex gap-5 xl:gap-10 items-center">
        <div className="flex flex-col gap-2 w-24">
          <span className="text-gray-150 text-xs">Status</span>
          <span
            className={`px-6 py-1  ${
              resultOffer?.offerStatus === "live"
                ? "bg-green-100 text-green-200"
                : resultOffer?.offerStatus === "draft"
                ? "text-yellow-200 bg-yellow-100"
                : resultOffer?.offerStatus === "expired"
                ? "bg-bright-gray text-gray-150"
                : "bg-red-100 text-red-200"
            } rounded-full w-fit text-3.25 capitalize`}
          >
            {resultOffer?.offerStatus}
          </span>
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-gray-150 text-xs">Category</span>
          <span className="text-sm w-[70px] truncate">
            {resultOffer?.offerTag}
          </span>
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-gray-150 text-xs">Reward </span>
          <div>
            <div className="flex gap-2 items-center text-primary-blue">
              <img
                src={resultOffer?.offerRewardTokenImage}
                alt="reward token"
                className="w-4 h-4 "
              />
              <span className="text-sm text-black w-20 truncate">
                {resultOffer?.offerReward} {resultOffer?.offerRewardTokenName}
              </span>
            </div>{" "}
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-gray-150 text-xs">Time</span>
          <span className=" text-sm w-32 truncate">
            {" "}
            {resultOffer?.offerExpirationDate
              ? calcuateDateDifference(resultOffer.offerExpirationDate)
              : "unlimited"}
          </span>
        </div>
        <div className="my-auto">
          {resultOffer?.offerTotalReward && resultOffer?.offerParticipants ? (
            <>
              <div className="w-fit">
                <div className="flex gap-2 items-center">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleShowNext(resultOffer?.offerId);
                    }}
                  >
                    <LeftArrow />
                  </button>

                  {showNextMap[resultOffer?.offerId] ?? false ? (
                    <div className="flex gap-2 items-center">
                      <img
                        src={resultOffer?.offerRewardTokenImage}
                        alt=""
                        className="w-4 h-4 object-contain"
                      />
                      <span className="text-xs text-gray-150 font-medium w-32 truncate ">
                        {resultOffer?.distributedReward} /{" "}
                        {resultOffer?.offerTotalReward}{" "}
                        {resultOffer?.offerRewardTokenName}
                      </span>
                    </div>
                  ) : (
                    <div className="flex gap-2 items-center">
                      <ProfileIcon className="text-primary-blue w-4 h-4" />
                      <span className="text-xs text-gray-150 font-medium w-32 truncate">
                        {resultOffer?.currentEntry} /{" "}
                        {resultOffer?.offerParticipants} Participants
                      </span>{" "}
                    </div>
                  )}
                  <button
                    className="rotate-180"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleShowNext(resultOffer?.offerId);
                    }}
                  >
                    <LeftArrow />
                  </button>
                </div>

                <div className="py-1 mt-2">
                  <div className="h-0.5 md:h-1.5 w-[170px] bg-blue-100 rounded-full overflow-hidden mx-auto">
                    <div
                      className="h-full bg-primary-gradient rounded-full"
                      style={{
                        width: `${
                          showNextMap[resultOffer?.offerId] ?? false
                            ? (resultOffer?.distributedReward /
                                resultOffer?.offerTotalReward) *
                              100
                            : (resultOffer?.currentEntry /
                                resultOffer?.offerParticipants) *
                              100
                        }%`,
                        transition: "width 1s linear",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {resultOffer?.offerParticipants ? (
                <>
                  <div className="flex gap-2 items-center">
                    <span className="text-xs text-gray-150 font-medium ">
                      {resultOffer?.currentEntry} /{" "}
                      {resultOffer?.offerParticipants} Participants
                    </span>
                  </div>
                  <div className="py-1 w-full mt-2">
                    <div className="h-0.5 md:h-1.5 w-[180px] bg-blue-100 rounded-full overflow-hidden">
                      <div
                        className="h-full bg-primary-gradient rounded-full"
                        style={{
                          width: `${
                            (resultOffer?.currentEntry /
                              resultOffer?.offerParticipants) *
                            100
                          }%`,
                          transition: "width 1s linear",
                        }}
                      ></div>
                    </div>
                  </div>
                </>
              ) : resultOffer?.offerTotalReward ? (
                <>
                  <div className="flex gap-2 items-center">
                    <img
                      src={resultOffer?.offerRewardTokenImage}
                      alt=""
                      className="w-4 h-4 object-contain"
                    />

                    <span className="text-xs text-gray-150 font-medium ">
                      {resultOffer?.distributedReward} /{" "}
                      {resultOffer?.offerTotalReward}{" "}
                      {resultOffer?.offerRewardTokenName}
                    </span>
                  </div>
                  <div className="py-1 w-full mt-2">
                    <div className="h-0.5 md:h-1.5 w-[180px] bg-blue-100 rounded-full overflow-hidden">
                      <div
                        className="h-full bg-primary-gradient rounded-full"
                        style={{
                          width: `${
                            (resultOffer?.distributedReward /
                              resultOffer?.offerTotalReward) *
                            100
                          }%`,
                          transition: "width 1s linear",
                        }}
                      ></div>
                    </div>
                  </div>
                </>
              ) : (
                <span className="text-sm font-medium">unlimited</span>
              )}
            </>
          )}
        </div>
      </div>
      {/* performance overview */}
      <div className="mt-8 mb-6">
        <span className="text-base font-semibold">Performance Overview</span>
        <div className="grid grid-cols-3 gap-6 mt-6">
          {SummaryDetails?.map((data, index) => (
            <div
              className="px-8 py-6 shadow-3xl rounded-2xl bg-white hover:bg-primary-blue group"
              key={index}
            >
              <span className="text-3.25 font-medium text-secondary-gray group-hover:text-white">
                {data.title}
              </span>
              <div className="flex gap-2 items-center mt-4">
                <div className="text-primary-blue group-hover:text-white">
                  {data.iconComponent}
                </div>
                {data.count &&
                data.count !== "$ undefined" &&
                data.count !== "NaN%" &&
                data.count !== "$0.00" &&
                data.count !== "0%" &&
                data.count !== "undefined %" ? (
                  <div className="gap-1 flex items-center min-w-full w-auto">
                    <span className="pt-1.5 text-sm py-auto xl:text-7 font-semibold group-hover:text-white truncate h-8">
                      {data.count}
                    </span>
                  </div>
                ) : (
                  <div className="w-full flex justify-center">
                    <div className="border-2 border-black w-10" />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <PauseOfferModal
        open={pauseOfferModal}
        onClose={() => {
          setOpenPauseOfferModal(false);
          setSelectedAction(null);
          setSelectedId(null);
        }}
        pauseOfferLoading={pauseOfferLoading}
        selectedId={selectedId}
      />
    </>
  );
};
export default OfferAnalytics;
