import { useEffect, useState } from "react";
import TextSelect from "./TextSelect";

const CustomeDividedInput = ({
  selectedBlockchain,
  setSelectedBlockchain,
  setBlockChainAddress,
  blockchainAddress,
  options,
  placeholder,
  setTokenInfoData,
  setValidAddress,
  selectedCategory,
  isRequiredEmpty,
  setTokenInfoLoading,
  selectedOption,
}) => {
  const [debounceTimer, setDebounceTimer] = useState(null);
  const sessionJwtToken = localStorage.getItem("sessionJwtToken");
  const [isDataInTokenInfo, setIsDataInTokenInfo] = useState(null);

  const filteredOptions =
    selectedCategory === "🏞️ NFT"
      ? options.filter((option) =>
          selectedOption === "list"
            ? option.value !== "bsc" && option.value !== "optimism" && option.value !== "linea" && option.value !== "manta" && option.value !== "avalanche"  
            : options
        )
      : selectedOption === "hold" || selectedOption === "deposit liquidity"
      ? options.filter((option) => option.value !== "solana")
      : options;

  const fetchTokenInfo = async (address) => {
    setTokenInfoLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/token_info?protocol_type=${
          selectedCategory === "🏞️ NFT" ? "token_721" : "token_20"
        }&token_contract_address=${address}&chain_sort_name=${
          selectedBlockchain === "Polygon"
            ? "polygon"
            : selectedBlockchain === "Solana"
            ? "solana"
            : selectedBlockchain === "Base"
            ? "base"
            : selectedBlockchain === "Arbitrum"
            ? "arbitrum"
            : selectedBlockchain === "Optimism"
            ? "op"
            : selectedBlockchain === "Linea"
            ? "linea"
            : selectedBlockchain === "Manta"
            ? "manta"
            : selectedBlockchain === "Avalanche"
            ? "avaxc"
            : selectedBlockchain?.toLocaleLowerCase()?.slice(0, 3)
        }`,
        {
          method: "GET",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Authorization: sessionJwtToken,
          },
          redirect: "follow",
          referrerPolicy: "no-referrer",
        }
      );
      const { success, data } = await response.json();
      if (success) {
        setTokenInfoData(data);
        setIsDataInTokenInfo(data);
        !success && setValidAddress(true);
      }
      setTokenInfoLoading(false);
    } catch (error) {
      console.log(error);
      setTokenInfoLoading(false);
    }
  };

  const handleChange = (e) => {
    setIsDataInTokenInfo(null);
    const { value } = e.target;
    setBlockChainAddress(value); // Update the state with the new value
    setValidAddress(false);
    // Clear the previous timer
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    if (value.trim() !== "") {
      const newTimer = setTimeout(async () => {
        selectedOption !== "stake/liquid stake" && fetchTokenInfo(value);
      }, 1000);

      // Update the timer ID in the state
      setDebounceTimer(newTimer);
    }
  };

  useEffect(() => {
    if (blockchainAddress) {
      selectedOption !== "stake/liquid stake" &&
        fetchTokenInfo(blockchainAddress);
    }
  }, [selectedBlockchain]);

  return (
    <>
      <div
        className={`border ${
          isRequiredEmpty && blockchainAddress === ""
            ? "border-red-500"
            : "border-border-gray"
        } rounded-2xl max-w-[480px]`}
      >
        <div className="px-6 py-2.5 flex items-center">
          <TextSelect
            options={filteredOptions}
            selectedCategory={selectedCategory}
            value={selectedBlockchain}
            onChange={(value) => {
              setSelectedBlockchain(value);
            }}
          />
          <div className="border-r border-r-gray-300 ml-10 mr-6 h-8" />
          <input
            type="text"
            className=" text-start w-full border-transparent focus:ring-0 focus:border-transparent text-sm"
            placeholder={placeholder}
            value={blockchainAddress}
            onChange={handleChange}
          />
        </div>
      </div>
      {isDataInTokenInfo?.length === 0 && (
        <span className="text-red-200 text-xs">
          Contract address not recognised. Please try again
        </span>
      )}
    </>
  );
};
export default CustomeDividedInput;
