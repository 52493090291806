import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAuthPasswordLess } from "../../contexts/AuthPasswordLessContext";
import { SettingsIcon } from "../../icons/icons";

export default function Sidebar() {
  const { currentUser } = useAuthPasswordLess();
  const history = useHistory();
  const { pathname } = useLocation();

  const [openSection, setOpenSection] = useState({});

  const nestedList = [
    {
      id: 11,
      rootName: "Campaign",
      rootIcon: "feedback.svg",
      nestedButtonName: "Create New",
      nestedLinkRedirect: "/new-campaign",
      overViewPageName: "Overview",
      overViewPageLink: "/campaign-overview",
    },
    {
      id: 12,
      rootName: "Cashback",
      rootIcon: "discount.svg",
      nestedButtonName: "Create New",
      nestedLinkRedirect: "/new-offer",
      overViewPageName: "Overview",
      overViewPageLink: "/offers-overview",
    },
  ];

  const handleToggleClick = (sectionId) => {
    setOpenSection((prev) => ({
      ...prev,
      [sectionId]: !prev[sectionId],
    }));
  };

  return (
    <aside className="h-screen fixed overflow-hidden flex flex-col justify-between py-8 px-4 border-r border-white-200">
      <div>
        <img className="w-[120px] mx-auto" src="/linkko-new.png" alt="Linkko" />
        <nav className="mt-14">
          <ul className="ml-0">
            {nestedList.map((data, index) => (
              <div key={index}>
                <div
                  className="flex items-center text-[#141522] gap-3 px-5 py-2.5 rounded-[10px] mb-4 cursor-pointer justify-between"
                  onClick={() => handleToggleClick(data.id)}
                >
                  <div className="flex gap-2">
                    <img
                      src={`/assets/icons/${data.rootIcon}`}
                      alt="feedback-us"
                    />
                    <span className="text-sm"> {data.rootName}</span>
                  </div>
                  <img
                    src="/assets/icons/arrow-down-black.svg"
                    alt="down arrow"
                    className={`${openSection[data.id] && "rotate-180"}`}
                  />
                </div>
                {openSection[data.id] && (
                  <>
                    <div
                      className={`px-5 py-2.5 flex items-center justify-start gap-2 p-2 relative rounded-lg cursor-pointer group hover:bg-primary-gradient mb-4 mx-2 ${
                        pathname === `${data.nestedLinkRedirect}` &&
                        "bg-primary-gradient"
                      }`}
                      onClick={() => history.push(data.nestedLinkRedirect)}
                    >
                      <div class="relative w-5 h-5">
                        <div
                          class={`absolute inset-0 flex items-center justify-center transition-opacity opacity-0 group-hover:opacity-100
                 ${
                   pathname === `${data.nestedLinkRedirect}` && "opacity-100"
                 } `}
                        >
                          <img
                            src="/assets/icons/plus-fill-light.svg"
                            alt="plus"
                          />
                        </div>
                        <div
                          class={`absolute inset-0 flex items-center justify-center transition-opacity opacity-100 group-hover:opacity-0
                    ${
                      pathname === `${data.nestedLinkRedirect}` && "invisible"
                    }`}
                        >
                          <img
                            src="/assets/icons/plus_analytics.svg"
                            alt="plus"
                          />
                        </div>
                      </div>

                      <div
                        className={`font-medium bg-primary-gradient text-sm text-transparent bg-clip-text group-hover:text-white ${
                          pathname === `${data.nestedLinkRedirect}` &&
                          "text-white"
                        }`}
                      >
                        Create New
                      </div>
                    </div>
                    <Link to={data.overViewPageLink} key={data.id}>
                      <div
                        className={`flex items-center text-[#141522] px-5 py-2.5 rounded-[10px] mb-4 mx-2  ${
                          pathname === data.overViewPageLink
                            ? "bg-[#F7F7F7]"
                            : ""
                        }`}
                      >
                        <li className="flex gap-2">
                          <img src={data.sidebarIcon} alt={data.sidebarAlt} />
                          <span className="items-center flex text-sm">
                            {data.overViewPageName}
                          </span>
                        </li>
                      </div>
                    </Link>{" "}
                  </>
                )}
              </div>
            ))}
            <Link to="/settings">
              <div
                className={`flex items-center text-[#141522] px-5 py-2.5 rounded-[10px] mb-4 ${
                  pathname === "/settings" ? "bg-[#F7F7F7]" : ""
                }`}
              >
                <li className="flex gap-2">
                  <SettingsIcon />
                  <span className="items-center flex text-sm">
                    {/* {data.overViewPageName} */}
                    Setting
                  </span>
                </li>
              </div>
            </Link>
          </ul>
        </nav>
      </div>
      <div className="flex flex-col gap-4">
        <a
          href={`https://linkko.typeform.com/to/GyetDKul#email=${currentUser?.email}&uid=${currentUser?.uid}`}
          target="_blank"
          rel="noreferrer"
        >
          <li className="flex items-center w-full cursor-pointer rounded-lg py-2.5 px-5 gap-3 text-sm">
            <img src="/assets/icons/help.svg" alt="feedback-us" />
            Help
          </li>
        </a>
        <a
          href={`https://linkko.typeform.com/to/GiMoVt56#email=${currentUser?.email}&uid=${currentUser?.uid}`}
          target="_blank"
          rel="noreferrer"
        >
          <li className="flex gap-2 py-2.5 px-5 items-center text-sm">
            <img src="/assets/icons/feedback.svg" alt="feedback-us" />
            Feedback
          </li>
        </a>
      </div>
    </aside>
  );
}
