import React from "react";
import cn from "classnames";
import Lottie from "lottie-react";
import LoadingAnimation from "../utils/linkko-animate.json";

const LoadingScreen = ({ className, animationClass }) => {
  return (
    <div
      className={cn(
        "flex items-center justify-center sm:gap-12 gap-8 ",
        className
      )}
    >
      <Lottie
        animationData={LoadingAnimation}
        className={cn(animationClass ? animationClass : "h-72")}
      />
    </div>
  );
};

export default LoadingScreen;
