import React from "react";

const WebVectors = () => {
  return (
    // AUTH SCREEN VECTORS FOR WEB DESIGN
    <div className="invisible md:visible">
      <img
        className="absolute -bottom-10 left-0"
        src="/assets/images/login-vector-1.svg"
        alt="vector"
      />
      <img
        className="absolute -right-10 -top-12"
        src="/assets/images/login-vector-2.svg"
        alt="vector"
      />
    </div>
  );
};

export default WebVectors;
