/* eslint-disable no-template-curly-in-string */
export const TELEGRAM = "telegram";
export const DISCORD = "discord";
export const WEBSITE = "website";
export const NOTION = "notion";
export const WEBSITE_SAVE = "website-save";

export const ACTIVE = "active";
export const INACTIVE = "inactive";
export const SAVE = "save";
export const CONNECT_APP = "connect-app";

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const inputBlackchainOptions = [
  {
    value: "polygon",
    label: "Polygon",
    logo: "/blockchainIcons/polygon-matic-logo.svg",
  },
  {
    value: "ethereum",
    label: "Ethereum",
    logo: "/blockchainIcons/ethereum-matic-logo.svg",
  },
  {
    value: "solana",
    label: "Solana",
    logo: "/blockchainIcons/solana-logo.svg",
  },
  {
    value: "arbitrum",
    label: "Arbitrum",
    logo: "/blockchainIcons/arbitrum.svg",
  },
  { 
    value: "bsc",
    label: "BSC",
    logo: "/blockchainIcons/binance.svg",
  },
  {
    value: "optimism",
    label: "Optimism",
    logo: "/blockchainIcons/Optimism.svg",
  },
  {
    value: "base",
    label: "Base",
    logo: "/blockchainIcons/base-logo.png",
  },
  {
    value: "linea",
    label: "Linea",
    logo: "/blockchainIcons/linea.png",
  },
  {
    value: "manta",
    label: "Manta",
    logo: "/blockchainIcons/manta.png",
  },
  {
    value: "avalanche",
    label: "Avalanche",
    logo: "/blockchainIcons/avax.png",
  }
];

export const DEXData = [
  {
    value: "1inch",
    label: "1inch",
    logo: "/DEXIcons/1inch.svg",
  },
  {
    value: "jumper",
    label: "Jumper",
    logo: "/DEXIcons/Jumper.svg",
  },
  {
    value: "pancakeswap",
    label: "Pancakeswap",
    logo: "/DEXIcons/Pancakeswap.svg",
  },
  {
    value: "sushiswap",
    label: "SushiSwap",
    logo: "/DEXIcons/SushiSwap.svg",
  },
  {
    value: "traderJoe",
    label: "TraderJoe",
    logo: "/DEXIcons/TraderJoe.svg",
  },
  {
    value: "uniswap",
    label: "Uniswap",
    logo: "/DEXIcons/Uniswap.svg",
  },
  {
    value: "woofi",
    label: "Woofi",
    logo: "/DEXIcons/Woofi.svg",
  },
];

// const collectionOfNFTs = [
//   { value: "nft1", label: "Kaiju Kingz", logo: "/nftCollection/nftimg1.svg" },
//   { value: "nft2", label: "BAYC", logo: "/nftCollection/nftimg2.svg" },
//   { value: "nft3", label: "Mutant BAYC", logo: "/nftCollection/nftimg3.svg" },
//   { value: "nft4", label: "Azuki", logo: "/nftCollection/nftimg4.svg" },
//   { value: "nft5", label: "Doodles", logo: "/nftCollection/nftimg5.svg" },
//   { value: "nft6", label: "Pudgy Penguin", logo: "/nftCollection/nftimg6.svg" },
// ];

export const durationData = [
  {
    value: "month",
    label: "Month",
  },
  {
    value: "week",
    label: "Week",
  },
  {
    value: "day",
    label: "Day",
  },
];

export const blockchainLabels = {
  Polygon: {
    labelName: "MATIC",
    logoPath: "/blockchainIcons/polygon-matic-logo.svg",
  },
  Ethereum: {
    labelName: "ETH",
    logoPath: "/blockchainIcons/ethereum-matic-logo.svg",
  },
  Solana: {
    labelName: "ETH",
    logoPath: "/blockchainIcons/solana-logo.svg",
  },
  Arbitrum: {
    labelName: "ETH",
    logoPath: "/blockchainIcons/arbitrum.svg",
  },
  BSC: {
    labelName: "BNB",
    logoPath: "/blockchainIcons/binance.svg",
  },
  Optimism: {
    labelName: "ETH",
    logoPath: "/blockchainIcons/Optimism.svg",
  },
};

export const optionsMappingWithCategory = {
  "💰 Token": ["Buy", "Hold"],
  "🏞️ NFT": ["Mint", "Buy", "Hold", "List"],
  "💵 DeFi": ["Deposit Liquidity", "Stake/Liquid Stake"],
  "🤝 DEX": ["Swap any", "Swap specific token"],
  "🔗 Offchain": ["Sign Up", "Buy"],
};

export const categoryOption = [
  { value: "all", label: "All" },
  { value: "nft", label: "NFT" },
  { value: "defi", label: "DeFi" },
  { value: "crypto", label: "Crypto" },
  { value: "protocols", label: "Protocols" },
  { value: "offchain", label: "Offchain" },
];

export const OfferDroupDown = [
  { value: "pause", label: "Pause offer" },
  { value: "suspend", label: "Suspend offer" },
  { value: "del", label: "Delete offer" },
];

export const blockchainMappings = {
  eth: {
    imgSrc: "ethereum-matic-logo.svg",
    displayName: "Ethereum",
  },
  polygon: {
    imgSrc: "polygon-matic-logo.svg",
    displayName: "Polygon",
  },
  base: {
    imgSrc: "base-logo.png",
    displayName: "Base",
  },
  arbitrum: {
    imgSrc: "arbitrum.svg",
    displayName: "Arbitrum",
  },
  bsc: {
    imgSrc: "binance.svg",
    displayName: "BSC",
  },
  sol: {
    imgSrc: "solana-logo.svg",
    displayName: "Solana",
  },
  linea: {
    imgSrc: "linea.png",
    displayName: "Linea"
  },
  manta: {
    imgSrc: "manta.png",
    displayName: "Manta"
  },
  avalanche: {
    imgSrc: "avaxc.png",
    displayName: "Avalanche"
  }
};

export const tags = [
  "🌐 DeFi",
  "💹 Trading",
  "🌱 Staking / Restaking",
  "💧 Liquid Staking",
  "🖼️ NFT",
  "💰 Token",
  "🎮 Gaming",
  "🏛️ DAO",
  "🏪 Marketplace",
  "🤝 DEX",
  "🏦 CEX",
  "🔄 Other",
];
