import { Link } from "react-router-dom/cjs/react-router-dom.min";
import WebVectors from "./components/WebVectors";
import MobileVectors from "./components/MobileVectors";

const Options = () => {
  return (
    <div className="flex relative justify-center overflow-hidden items-center h-screen">
      <div className="flex flex-col gap-16">
        <div className="flex flex-col justify-center items-center gap-[22px] z-30">
          <img src="/linkko-new.png" alt="linkko" className="h-16 z-30" />
          <span className="text-[13px] text-text-gray font-medium">
            👋 Hi, welcome back!
          </span>
        </div>

        <div className="flex flex-col gap-4 justify-center items-center">
          <Link to="/login">
            <button className="flex items-center justify-center text-white bg-primary-gradient md:h-auto h-12 py-4 px-9 rounded-2xl font-bold w-56 hover:shadow-primaryShadow transition duration-200">
              Login
            </button>
          </Link>
          <span className="text-xs font-medium text-text-gray">- OR -</span>

          <Link to="/signup">
            <button className="flex items-center justify-center bg-white text-primary-blue border-[1px] border-primary-blue md:h-auto h-12 py-4 px-9 rounded-2xl font-bold w-56 hover:shadow-primaryShadow transition duration-200">
              Create an Account
            </button>
          </Link>
        </div>
      </div>

      <WebVectors />
      <MobileVectors />
    </div>
  );
};

export default Options;
