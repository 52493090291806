import MobileVectors from "./components/MobileVectors";
import WebVectors from "./components/WebVectors";

const VisitSite = () => {
  return (
    <div className="flex relative justify-center overflow-hidden items-center h-screen">
      <div className="flex flex-col gap-16 mb-20">
        <div className="flex flex-col md:gap-8 gap-4 justify-center items-center">
          <img
            src="/assets/icons/monitor.svg"
            alt="linkko"
            className="md:h-20 md:w-20 h-14 w-14 z-20"
          />
          <span className="font-medium text-center">
            {`As of now, we run only on desktop :) `}
            <br />
            Mobile version will come soon
          </span>
          <a href="https://www.linkko.io/">
            <button className="flex items-center justify-center text-white bg-primary-gradient md:h-auto h-12 py-4 px-9 rounded-2xl font-bold w-56 mt-4 hover:shadow-primaryShadow transition duration-200">
              Visit Website
            </button>
          </a>
        </div>
      </div>
      <WebVectors />
      <MobileVectors />
    </div>
  );
};

export default VisitSite;
