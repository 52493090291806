import { useEffect, useState } from "react";
import {
  LoaderIcon,
  RoundBorderTelegram,
  RoundedBorderDiscord,
  RoundedBorderTwitter,
  SettingsIcon,
  VerifiedIcon,
} from "../../icons/icons";
import { useAuthPasswordLess } from "../../contexts/AuthPasswordLessContext";
import Header from "../common/Header";
import DragImageComponent from "../Overview/DragImageComponent";
import Input from "./Input";
import EditorComponent from "../common/offerComponents/Editor";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import TagList from "./TagList";
import Error from "../common/offerComponents/Error";

const Settings = () => {
  const { currentUser, storeGetClientData, callGetClient } =
    useAuthPasswordLess();

  const [companyName, setCompanyName] = useState(
    storeGetClientData?.companyName || null
  );
  const [files, setFiles] = useState([]);
  const initialState = {
    picture: "",
    file_type: "",
  };

  const [error, setError] = useState("");
  const [showData, setShowData] = useState(initialState);
  const rangeData = ["1-10", "10-100", "100-1000", "1000+"];
  const [companySize, setCompanySize] = useState(
    storeGetClientData?.companySize || null
  );
  const [companyUrl, setCompanyUrl] = useState(
    storeGetClientData?.companyUrl || null
  );
  const [editorDescription, setEditorDescription] = useState(
    storeGetClientData?.companyDescription || null
  );

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [selected, setSelected] = useState(
    storeGetClientData?.companyTags || []
  );
  const [discordURL, setDiscordURL] = useState(
    storeGetClientData?.companyDiscord || null
  );
  const [twitterURL, setTwitterURL] = useState(
    storeGetClientData?.companyTwitter || null
  );
  const [TelegramURL, setTelegramURL] = useState(
    storeGetClientData?.companyTelegram || null
  );
  const [isValidDiscordURL, setIsValidDiscordURL] = useState("");
  const [isValidTwitterURL, setIsValidTwitterURL] = useState("");
  const [isValidTelegramURL, setIsValidTelegramURL] = useState("");
  const [isValidCompanyURL, setIsValidCompanyURL] = useState("");

  const twitterRegex = twitterURL?.includes("twitter")
    ? /^https?:\/\/(www\.)?twitter\.com\/[a-zA-Z0-9_]+\/?$/
    : /^https?:\/\/(www\.)?x\.com\/[a-zA-Z0-9_]+\/?$/;
  const discordRegex = /^https?:\/\/(www\.)?(discord\.gg|discord\.com)\/[a-zA-Z0-9]+\/?$/;
  const telegramRegex =
    /^https?:\/\/(www\.)?t\.me\/([a-zA-Z0-9_]+|joinchat\/[a-zA-Z0-9_]+)/;
  const companyURLRegex = /^(ftp|http|https):\/\/[^ "]+$/;

  const validateURL = (url, platform) => {
    if (platform === "twitter") {
      return twitterRegex.test(url);
    } else if (platform === "discord") {
      return discordRegex.test(url);
    } else if (platform === "telegram") {
      return telegramRegex.test(url);
    } else if (platform === "companyURL") {
      return companyURLRegex.test(url);
    }
    return false;
  };

  const sessionJwtToken = localStorage.getItem("sessionJwtToken");
  const [updateClientLoading, setUpdateClientLoading] = useState(false);

  const handleSubmit = () => {
    setIsValidDiscordURL("");
    setIsValidTwitterURL("");
    // Check if the file type is video/mp4, show error and reset the state if true
    if (showData?.file_type === "video/mp4") {
      setError("Please upload an image for the company logo");
      setFiles([]);
      setShowData(initialState);
      return; // Exit early since we have encountered an error
    }

    // Validate the Discord URL if it is provided
    if (discordURL) {
      const isValidDiscord = validateURL(discordURL, "discord");
      setIsValidDiscordURL(isValidDiscord);

      if (!isValidDiscord) {
        // If invalid, exit without calling update
        return;
      }
    }

    // Validate the Twitter URL if it is provided
    if (twitterURL) {
      const isValidTwitter = validateURL(twitterURL, "twitter");
      setIsValidTwitterURL(isValidTwitter);

      if (!isValidTwitter) {
        // If invalid, exit without calling update
        return;
      }
    }
    // Validate the telegram URL if it is provided
    if (TelegramURL) {
      const isValidTelegram = validateURL(TelegramURL, "telegram");
      setIsValidTelegramURL(isValidTelegram);

      if (!isValidTelegram) {
        // If invalid, exit without calling update
        return;
      }
    }
    // Validate the comapny URL if it is provided
    if (companyUrl) {
      const isValidComapnyURL = validateURL(companyUrl, "companyURL");
      setIsValidCompanyURL(isValidComapnyURL);

      if (!isValidComapnyURL) {
        // If invalid, exit without calling update
        return;
      }
    }

    // If everything checks out, call the update function
    callUpdateClient();
  };
  // call update_client to store company url
  const callUpdateClient = async () => {
    setUpdateClientLoading(true);
    const formData = new FormData();
    formData.append("client_id", storeGetClientData?.clientId);
    const response = await fetch(showData?.picture);
    const blob = await response.blob();
    // Convert the blob to a File object
    const file = new File([blob], showData?.picture, {
      type: showData?.file_type,
    });
    // Append the File object to the FormData
    files?.length !== 0 && formData.append("company_logo", file);
    companyName && formData.append("company_name", companyName);
    companySize && formData.append("company_size", companySize);
    companyUrl && formData.append("company_url", companyUrl);
    editorDescription?.editorContent &&
      formData.append("company_description", editorDescription?.editorContent);
    selected && formData.append("company_tags", selected);
    discordURL && formData.append("company_discord", discordURL);
    TelegramURL && formData.append("company_telegram", TelegramURL);
    twitterURL && formData.append("company_twitter", twitterURL);

    await fetch(`${process.env.REACT_APP_BACKEND_URL}/update_client`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: formData,
      headers: {
        Authorization: sessionJwtToken,
      },
    })
      .then((res) => res.json())
      .then(({ success, message }) => {
        if (success) {
          callGetClient();
          setUpdateClientLoading(false);
        } else {
          setError(message);
          setUpdateClientLoading(false);
          setFiles([]);
          setShowData(initialState);
        }
      })
      .catch((err) => {
        setUpdateClientLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    // Check for companyDescription on component mount or update
    if (storeGetClientData?.companyDescription) {
      const initialContent = storeGetClientData?.companyDescription;
      const contentState = ContentState.createFromBlockArray(
        convertFromHTML(initialContent)
      );
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [storeGetClientData]);

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex gap-2 items-start">
          <div className="text-primary-blue p-2 rounded-lg bg-light-blue w-fit">
            <SettingsIcon />
          </div>
          <div className="flex flex-col gap-2">
            <span className="text-base font-bold">Setting</span>
            <span className="text-xs text-secondary-gray">
              Change the information based on your profile{" "}
            </span>
          </div>
        </div>
        <Header userEmail={currentUser?.email} />
      </div>{" "}
      <div className="max-w-[480px]">
        <div className="mb-2 mt-6">
          <span className="text-xs text-gray-150">Company display name </span>
          <span className="text-red-200 ml-2">*</span>
          <Input state={companyName} setState={setCompanyName} />
        </div>
        <div className="mt-4">
          <span className="text-xs text-gray-150">Company Logo </span>
          <span className="text-red-200 ml-2">*</span>
          {files?.length === 0 && storeGetClientData?.companyLogo && (
            <div className="flex gap-4 items-center mt-4">
              <img
                src={storeGetClientData?.companyLogo}
                alt="logo"
                className="w-12 h-12 rounded-full object-contain"
              />
              <div className="flex items-center gap-1">
                <span className="text-sm">
                  {storeGetClientData?.companyName}
                </span>
                <VerifiedIcon />
              </div>
            </div>
          )}

          <DragImageComponent
            setFiles={setFiles}
            setShowData={setShowData}
            files={files}
            setError={setError}
            isVisibleName={true}
            companyName={companyName}
          />
        </div>

        <div className="my-4">
          <span className="text-xs text-gray-150">Company Size</span>
          <span className="text-red-200 ml-2">*</span>

          <div className="mt-2 flex gap-2">
            {rangeData?.map((data, index) => (
              <div
                className={`border px-6 py-2 text-sm w-fit rounded-full cursor-pointer ${
                  companySize === data
                    ? "bg-primary-gradient text-white"
                    : "border-border-gray text-gray-100"
                }`}
                key={index}
                onClick={() => {
                  setCompanySize(data);
                }}
              >
                {data}
              </div>
            ))}
          </div>
        </div>
        <div>
          <span className="text-xs text-gray-150">Company URL</span>

          <Input state={companyUrl} setState={setCompanyUrl} />

          {isValidCompanyURL === false && (
            <div className="w-fit">
              <Error
                message={"Enter in a valid format: https://www.linkko.io/"}
              />
            </div>
          )}
        </div>
        <div className="mt-4">
          <span className="text-xs text-gray-150">Company Description</span>
          <div className="mt-2">
            <EditorComponent
              setEditorDescription={setEditorDescription}
              editorDescription={editorDescription}
              editorState={editorState}
              setEditorState={setEditorState}
              storeGetClientData={storeGetClientData}
              placeholder={`Tell us more about ${storeGetClientData?.companyName}`}
            />
          </div>
        </div>
        <div className="my-4">
          <span className="text-xs text-gray-150">
            Company tags (Max.3 tags to select)
          </span>
          <TagList selected={selected} setSelected={setSelected} />
        </div>
        <div>
          <span className="text-xs text-gray-150">
            Link your social media account
          </span>

          <Input
            state={discordURL}
            setState={setDiscordURL}
            iconComponent={<RoundedBorderDiscord />}
          />
          {isValidDiscordURL === false && (
            <div className="w-fit">
              <Error message={"The URL is not valid, please try again"} />
            </div>
          )}

          <Input
            state={twitterURL}
            setState={setTwitterURL}
            iconComponent={<RoundedBorderTwitter />}
          />
          {isValidTwitterURL === false && (
            <div className="w-fit">
              <Error message={"The URL is not valid, please try again"} />
            </div>
          )}
          <Input
            state={TelegramURL}
            setState={setTelegramURL}
            iconComponent={<RoundBorderTelegram />}
          />
          {isValidTelegramURL === false && (
            <div className="w-fit">
              <Error message={"The URL is not valid, please try again"} />
            </div>
          )}
        </div>
        {error && <Error message={error} />}
      </div>
      <div className="flex max-w-[550px] gap-6 items-center">
        <button
          className={`rounded-3xl font-bold w-full py-3 my-8 text-sm flex items-center justify-center gap-4 ${
            !companyName ||
            !companySize ||
            (!showData?.picture && !storeGetClientData?.companyLogo)
              ? "bg-bright-gray text-secondary-gray"
              : "bg-primary-gradient text-white"
          }`}
          onClick={handleSubmit}
          disabled={
            updateClientLoading ||
            !companyName ||
            !companySize ||
            (!showData?.picture && !storeGetClientData?.companyLogo)
          }
        >
          {" "}
          Save changes
          {updateClientLoading ? (
            <div className="flex items-center justify-center animate-spin">
              <LoaderIcon />
            </div>
          ) : (
            ""
          )}
        </button>
        <div className="items-end justify-end flex ">
          <button
            className="text-red-200 text-sm underline underline-offset-2 "
            onClick={() => {
              // setShowData(initialState);
              // setCompanyName("");
              setError("");
              // setCompanySize(null);
              setCompanyUrl("");
              setSelected([]);
              // setFiles([]);
              setDiscordURL("");
              setTwitterURL("");
              setTelegramURL("");
              setIsValidDiscordURL("");
              setIsValidTwitterURL("");
              setEditorDescription("");
              setEditorState(EditorState.createEmpty());
            }}
          >
            Reset{" "}
          </button>
        </div>
      </div>
    </>
  );
};
export default Settings;
