import OTPInput from "react-otp-input";
import { Link, useHistory } from "react-router-dom";
import { useAuthPasswordLess } from "../../contexts/AuthPasswordLessContext";
import { useEffect, useState } from "react";

const ValidateOTP = () => {
  const {
    otp,
    setOTP,
    handleCallAuthenticate,
    currentUser,
    emailField,
    handleCallPasswordLess,
    OTPError,
    setOTPError,
    resendOTPMessage,
    callGetClient,
    userCreatedData,
    callAddUser,
  } = useAuthPasswordLess();
  const history = useHistory();
  const currentYear = new Date().getFullYear();
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);
  const [resetPressed, setResetPressed] = useState(false);
  const loginPath = localStorage.getItem("path");

  const handleOTP = (otp) => {
    setOTP(otp);
    setOTPError("");
    setResetPressed(false);
  };
  useEffect(() => {
    otp?.length === 6 && handleCallAuthenticate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp?.length]);

  useEffect(() => {
    if (currentUser) {
      if (userCreatedData) {
        callAddUser();
        history.push("/offers-overview");
      } else {
        callGetClient();
        history.push("/offers-overview");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, userCreatedData]);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        if (minutes > 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        } else {
          // Timer has reached 0
          clearInterval(countdownInterval);
          // You can perform additional actions here once the timer reaches 0
        }
      }
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(countdownInterval);
  }, [minutes, seconds]);

  return (
    <>
      <div className="h-screen relative">
        <div className="h-full flex justify-center items-center mx-auto">
          <div className="border border-border-gray rounded-3xl px-4 sm:px-[42px] py-10 bg-white mx-7 sm:mx-0 shadow-cardShadow">
            <Link
              to={loginPath?.slice(1) === "welcome" ? "/welcome" : "/sign-up"}
            >
              <div className="-mb-6 cursor-pointer">
                <img
                  src="/assets/icons/arrow-left-dark.svg"
                  alt="black back arrow"
                />
              </div>
            </Link>
            <div className="flex justify-center flex-col items-center">
              <img src="/assets/linkko.svg" alt="linkko logo" />
              <span className="text-base text-gray-150 mt-2">
                Get the most of web3 users{" "}
              </span>
            </div>
            <div className="flex flex-col text-center sm:text-start gap-2 mt-11">
              <span className="text-sm sm:text-xl font-bold text-black-100">
                Enter Verification Code
              </span>
              <span className="text-[13px] sm:text-sm font-medium text-gray-150">
                A 6-digit pass code was sent to you at{" "}
                <span className="font-bold text-black-100">{emailField}</span>
              </span>
            </div>
            <div className="sm:w-[465px]">
              <OTPInput
                containerStyle={{
                  width: "fit-content",
                }}
                inputStyle={{
                  width: "100%",
                  height: "3rem",
                  margin: "32px 5px",
                  fontSize: "1rem",
                  background: "transparent",
                  border: "1px solid #6196FD",
                  borderRadius: "8px",
                }}
                value={otp}
                onChange={handleOTP}
                numInputs={6}
                inputType="tel"
                renderInput={(props) => <input {...props} />}
              />
            </div>
            <div className="text-center sm:text-start w-64 sm:w-full mx-auto">
              <div className="text-sm font-medium text-gray-150">
                Your code expires in{"  "}
                <span className="w-10 inline-block text-center">{`${minutes}:${
                  seconds < 10 ? "0" : ""
                }${seconds}`}</span>
                Didn&apos;t get it?{" "}
                <span
                  className="font-bold text-primary-blue cursor-pointer"
                  onClick={() => {
                    handleCallPasswordLess();
                    setResetPressed(true);
                    setMinutes(5);
                    setSeconds(0);
                    setOTPError("");
                  }}
                >
                  Resend Code
                </span>
              </div>
            </div>
            {resetPressed && (
              <>
                {resendOTPMessage && resetPressed ? (
                  <span className="text-green-200 text-sm">
                    OTP sent successfully
                  </span>
                ) : (
                  <span className="text-red-200 text-sm">Fail to sent OTP</span>
                )}
              </>
            )}
            {OTPError !== "" && (
              <div className="w-60 truncate mt-2 mx-auto md:mx-0 text-center md:text-start">
                <span className="text-xs text-red-200">{OTPError}</span>
              </div>
            )}
          </div>
        </div>

        <img
          src="/assets/images/bottom-right.png"
          alt="bottom"
          className="absolute bottom-0 h-40 md:h-auto object-contain"
        />

        <img
          src="/assets/images/top-right.png"
          alt="top"
          className="absolute top-0 right-0 w-60 object-contain md:w-auto xl:w-[450px]"
        />
        <div className="absolute bottom-10 w-full flex justify-center">
          <span className=" text-[13px] font-medium text-gray-150">
            © Linkko {currentYear}. All right reserved
          </span>
        </div>
      </div>
    </>
  );
};
export default ValidateOTP;
