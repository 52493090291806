import { useEffect, useState } from "react";
import {
  Calender,
  Category,
  Discount,
  DollarSquare,
  Hamburger,
  Link,
  RoundedClock,
  RoundedQuestionMark,
  Warning,
} from "../../icons/icons";
import GradientButton from "../common/offerComponents/GradientButton";
import ToggleControl from "../common/offerComponents/ToggleControl";
import RadioInput from "../common/offerComponents/RadioInput";
import CustomeDividedInput from "../common/offerComponents/CustomeDividedInput";
import CustomeInput from "../common/offerComponents/CustomeAmountInput";
import Error from "../common/offerComponents/Error";
import TextSelect from "../common/offerComponents/TextSelect";
import EditorComponent from "../common/offerComponents/Editor";
import CustomeDateTimeInput from "../common/offerComponents/CustomeDateTimeInput";
import {
  DEXData,
  blockchainLabels,
  durationData,
  inputBlackchainOptions,
  optionsMappingWithCategory,
} from "../../utils/constant";
import PreviewComponent from "./PreviewComponent";
import { useAuthPasswordLess } from "../../contexts/AuthPasswordLessContext";
import { EditorState } from "draft-js";
import PreviewModal from "./PreviewModal";
import VerifyOffer from "./VerifyOffer";
import CompanyLogoModal from "../common/CompanyLogoModal";
import CustomeTextInput from "../common/offerComponents/CustomeTextInput";
import CustomeFloatAmountInput from "../common/offerComponents/CustomeFloatAmountInput";

const CreateNewOffer = () => {
  //state to select category like Token,NFT etc..
  const [selectedCategory, setSelectedCategory] = useState("");
  //State to select option from Radio button
  const [selectedOption, setSelectedOption] = useState("");
  //Find related radio with category
  const visibleOptions = optionsMappingWithCategory[selectedCategory] || [];
  //state to select input blockchain from droupdown
  const [selectedBlockchain, setSelectedBlockchain] = useState("Polygon");
  //state to get blockchain address in input
  const [blockchainAddress, setBlockChainAddress] = useState("");
  //state to get Entered Amount
  const [addedAmount, setAddedAmount] = useState(null);
  //state to show Error Message after writing blockchain Address
  const [validAddress, setValidAddress] = useState(false);
  //State to store duration
  const [duration, setDuration] = useState(null);
  //state to store DEX selected value
  const [selectedDEX, setSelectedDEX] = useState("1inch");
  //State to store link to offer
  const [linkToOffer, setLinkToOffer] = useState("");
  //state to store Editor description
  const [editorDescription, setEditorDescription] = useState("");
  //state to store reward amount
  const [rewardAmount, setRewardAmount] = useState("");
  //satte to store number of participants for reward
  const [totalParticipant, setTotalParticipant] = useState(null);
  //state to store offer start duration cat.
  const [startDuration, setStartDuration] = useState(null);
  //state to store offer end duration cat.
  const [endDuration, setEndDuration] = useState(null);
  //state to store present duration
  const [presentPeriod, setPresentPeriod] = useState(null);
  //state to store selected durations type
  const removeCommas = (str) => {
    return str?.replace(/,/g, ""); // Replaces all commas with nothing
  };
  const preventNegative = (e) => {
    if (e.key === "-") {
      e.preventDefault();
    }
  };
  const [selectedDuration, setSelectedDurations] = useState("Week");
  const totalDeposit = (
    Number(removeCommas(totalParticipant)) *
    Number(removeCommas(rewardAmount)) *
    1.2
  ).toLocaleString("en-US");

  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const handleDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  const sessionJwtToken = localStorage.getItem("sessionJwtToken");
  const { storeGetClientData } = useAuthPasswordLess();
  //state to store tokenInfo data
  const [tokenInfoData, setTokenInfoData] = useState("");
  //state to show require fields are empty
  const [isRequiredEmpty, setIsRequiredEmpty] = useState(false);
  //state to open verify screen after successfull API call of publish offer
  const [openVerifyScreen, setOpenVerifyScreen] = useState(false);
  //to check it's edit offer
  const [updateOffer, setUpdateOffer] = useState(false);
  //store offerId if publish offer success
  const [offerId, setOfferId] = useState(null);
  // Modal to show after New offer created

  const [openOfferPreview, setOpenOfferPreview] = useState(false);

  const [publishOfferLoading, setPublishOfferLoading] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  //state to check enter link URL is right or not
  const [isValidUrl, setIsValidUrl] = useState(true);

  const [logoModal, setOpenLogoModal] = useState(false);

  //state to show loader when token_info is Loading
  const [tokenInfoLoading, setTokenInfoLoading] = useState(false);
  const [nodeAddress, setNodeAddress] = useState(null);
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

  useEffect(() => {
    if (urlRegex.test(linkToOffer)) {
      setIsValidUrl(true);
    }
  });

  useEffect(() => {
    if (startDuration === "start now" || startDuration === "start later") {
      setSelectedStartDate(new Date());
    }
  }, [startDuration]);

  useEffect(() => {
    if (endDuration === "unlimited") {
      setSelectedEndDate(null);
    } else if (endDuration === "custom dates") {
      if (selectedStartDate) {
        // Create a new date from selectedStartDate
        const startDate = new Date(selectedStartDate);

        // Add 3 days to the date
        startDate.setDate(startDate.getDate() + 3);

        setSelectedEndDate(startDate);
      }
    }
  }, [endDuration, selectedStartDate]);

  useEffect(() => {
    if (endDuration === "preset period") {
      updateEndDate();
    }
  }, [presentPeriod, selectedDuration, selectedStartDate]);

  const updateEndDate = () => {
    let endDate = selectedStartDate ? new Date(selectedStartDate) : new Date();

    const period = parseInt(presentPeriod);
    if (!isNaN(period)) {
      switch (selectedDuration) {
        case "Month":
          endDate.setMonth(endDate.getMonth() + period);
          break;
        case "Week":
          endDate.setDate(endDate.getDate() + period * 7);
          break;
        case "Day":
          endDate.setDate(endDate.getDate() + period);
          break;
        default:
          break;
      }
      setSelectedEndDate(endDate);
    } else {
      setSelectedEndDate(null);
    }
  };

  const actionType =
    selectedOption &&
    selectedOption[0]?.toUpperCase() + selectedOption?.slice(1);

  const selectOfferType =
    selectedOption === "stake/liquid stake"
      ? "stake"
      : selectedOption.replace(/ +/g, "") === "swapany" ||
        selectedOption.replace(/ +/g, "") === "swapspecifictoken"
      ? "trade"
      : selectedOption.replace(/ +/g, "") === "depositliquidity"
      ? "deposit"
      : selectedOption.replace(/ +/g, "");

  const callPublishOffer = async () => {
    setPublishOfferLoading(true);
    const requestBody = {
      offer_id: offerId ? offerId : null,
      client_id: storeGetClientData?.clientId,
      token_name: tokenInfoData[0]?.token || tokenInfoData?.name,
      token_image:
        tokenInfoData[0]?.tokenImage || tokenInfoData?.metadata?.image,
      token_type: tokenInfoData[0]?.protocolType
        ? tokenInfoData[0]?.protocolType?.replace("ERC", "token_")
        : "token_20",
      blockchain:
        selectedBlockchain === "Polygon"
          ? "polygon"
          : selectedBlockchain === "Optimism"
          ? "op"
          : selectedBlockchain === "Arbitrum"
          ? "arbitrum"
          : selectedBlockchain === "Base"
          ? "base"
          : selectedBlockchain === "Linea"
          ? "linea"
          : selectedBlockchain === "Manta"
          ? "manta"
          : selectedBlockchain === "Avalanche"
          ? "avaxc"
          : selectedBlockchain?.toLocaleLowerCase()?.slice(0, 3),
      token_address:
        selectedOption !== "stake/liquid stake" ? blockchainAddress : null,
      required_amount: Number(addedAmount),
      node_address:
        selectedOption === "stake/liquid stake"
          ? blockchainAddress
          : nodeAddress,
      duration: Number(removeCommas(duration)),
      start_date: selectedStartDate,
      end_date: selectedEndDate,
      offer_name:
        selectedOption === "swap any"
          ? `${actionType} on ${selectedDEX} for ${addedAmount} USD`
          : `${actionType} ${addedAmount} ${
              tokenInfoData[0]?.token || tokenInfoData?.name
            }`,
      offer_description: editorDescription.editorContent,
      offer_reward_token_name: "USDC",
      offer_reward_token_address: "0x3c499c542cef5e3811e1192ce70d8cc03d5c3359",
      offer_reward_token_image:
        "https://static.oklink.com/cdn/wallet/logo/USDC.png",
      offer_blockchain: selectedOption === "swap any" ? selectedDEX : "polygon",
      offer_reward: Number(removeCommas(rewardAmount)),
      offer_url: linkToOffer,
      offer_type: selectOfferType,
      offer_tag:
        selectedCategory === "💰 Token"
          ? "Crypto"
          : selectedCategory === "🏞️ NFT"
          ? selectedCategory?.slice(4)
          : selectedCategory?.slice(3),
      offer_status: "draft",
      offer_interest: null,
      offer_max_participation: null,
      offer_total_reward:
        Number(removeCommas(totalParticipant)) *
        Number(removeCommas(rewardAmount)),
      offer_participants: Number(removeCommas(totalParticipant)),
      offer_expiration_date: selectedEndDate,
      contract_address:
        selectedOption === "deposit liquidity" ? blockchainAddress : null,
    };

    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/${
        updateOffer ? "update_offers" : "publish_offers"
      }`,
      {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(requestBody),
        headers: {
          Authorization: sessionJwtToken,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then(({ success, data }) => {
        if (success) {
          setOfferId(data);
          setOpenVerifyScreen(true);
        }
        setPublishOfferLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setPublishOfferLoading(false);
      });
  };

  const handleRequireFieldCheck = () => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    setIsValidUrl(urlRegex.test(linkToOffer));
    const isOffchain = selectedCategory === "🔗 Offchain";
    const areRequiredFieldsFilled =
      urlRegex.test(linkToOffer) &&
      (isOffchain
        ? selectedOption &&
          linkToOffer &&
          editorDescription.editorContent !== "" &&
          Number(removeCommas(rewardAmount)) >= 1 &&
          totalParticipant &&
          selectedStartDate
        : selectedOption === "hold" ||
          selectedOption === "deposit liquidity" ||
          selectedOption === "list"
        ? selectedOption &&
          linkToOffer &&
          editorDescription.editorContent !== "" &&
          Number(removeCommas(rewardAmount)) >= 1 &&
          totalParticipant &&
          selectedStartDate &&
          addedAmount &&
          Number(addedAmount) >= 1 &&
          duration
        : selectedOption !== "swap any"
        ? blockchainAddress !== "" &&
          selectedOption &&
          linkToOffer &&
          editorDescription.editorContent !== "" &&
          Number(removeCommas(rewardAmount)) >= 1 &&
          totalParticipant &&
          selectedStartDate &&
          addedAmount &&
          Number(addedAmount) >= 1
        : selectedOption &&
          linkToOffer &&
          editorDescription.editorContent !== "" &&
          Number(removeCommas(rewardAmount)) >= 1 &&
          totalParticipant &&
          selectedStartDate &&
          addedAmount &&
          Number(addedAmount) >= 1);

    setIsRequiredEmpty(!areRequiredFieldsFilled);

    if (areRequiredFieldsFilled) {
      if (
        storeGetClientData?.companyLogo === "" ||
        storeGetClientData?.companyLogo === null
      ) {
        setOpenLogoModal(true);
      } else {
        callPublishOffer();
      }
    }
  };

  return (
    <div>
      {!openVerifyScreen ? (
        <>
          {/* Header Section */}
          <div className="flex items-center justify-between">
            <div className="flex gap-2 items-start">
              <div className="text-primary-blue p-2 rounded-lg bg-light-blue w-fit">
                <Discount />
              </div>
              <div className="flex flex-col gap-2">
                <span className="text-base font-bold">
                  Setup Cashback Offer
                </span>
                <span className="text-xs text-secondary-gray">
                  Set the overall needs for your offer
                </span>
              </div>
            </div>
          </div>
          {/* Category section */}
          <div className="mt-7 flex flex-row gap-7 ">
            <div className=" w-1/2 lg:w-[55%]  2xl:w-3/5">
              <ToggleControl
                name="Category"
                isRequire=""
                subTitle="Category of your offer"
                iconName={<Category width={20} height={20} color={"#5E91FF"} />}
              />

              <div className="ml-7">
                {/* To select category */}
                <GradientButton
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  setSelectedOption={setSelectedOption}
                  setDuration={setDuration}
                  setBlockChainAddress={setBlockChainAddress}
                  setAddedAmount={setAddedAmount}
                  setSelectedBlockchain={setSelectedBlockchain}
                  setTokenInfoData={setTokenInfoData}
                  updateOffer={updateOffer}
                />
                {selectedCategory && (
                  <div className="mt-6">
                    <span className="text-text-gray text-xs">
                      Choose the action to perform by users
                    </span>
                  </div>
                )}
                {/* To select offerType  */}
                <RadioInput
                  options={visibleOptions}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                />
                {selectedOption && (
                  <>
                    {/* Droupdown for dex */}
                    {selectedCategory === "🤝 DEX" && (
                      <div className="border border-border-gray rounded-2xl px-6 py-4 my-4 max-w-[480px]">
                        <TextSelect
                          options={DEXData}
                          value={selectedDEX}
                          onChange={(value) => setSelectedDEX(value)}
                        />
                      </div>
                    )}
                    {/* Component for select blockchain and add a address */}
                    <div className="relative max-w-[480px]">
                      {selectedOption !== "swap any" &&
                        selectedCategory !== "🔗 Offchain" && (
                          <CustomeDividedInput
                            options={inputBlackchainOptions}
                            selectedBlockchain={selectedBlockchain}
                            setSelectedBlockchain={setSelectedBlockchain}
                            blockchainAddress={blockchainAddress}
                            setBlockChainAddress={setBlockChainAddress}
                            setTokenInfoData={setTokenInfoData}
                            setValidAddress={setValidAddress}
                            selectedCategory={selectedCategory}
                            isRequiredEmpty={isRequiredEmpty}
                            selectedOption={selectedOption}
                            placeholder={
                              selectedCategory === "🏞️ NFT"
                                ? "NFT contract address"
                                : selectedOption === "deposit liquidity"
                                ? "Token address to deposit liquidity"
                                : selectedOption === "stake/liquid stake"
                                ? "Staking contract address"
                                : selectedOption === "buy"
                                ? "Token address to buy"
                                : selectedOption === "hold"
                                ? "Token address to hold"
                                : "Contract Address"
                            }
                            setTokenInfoLoading={setTokenInfoLoading}
                          />
                        )}
                      {/* Show the tooltip only category is defi */}
                      {selectedCategory === "💵 DeFi" && (
                        <div className="absolute bottom-5 -right-5">
                          <div className="group relative my-auto">
                            <RoundedQuestionMark className="text-primary-blue cursor-pointer" />
                            <span className="group-hover:visible invisible absolute bottom-4 z-50 left-2 px-3 py-2 text-[11px] text-white bg-black-100 rounded-lg rounded-bl-none w-48">
                              Enter the address where users need to{" "}
                              {selectedOption === "deposit liquidity"
                                ? "deposit the funds"
                                : "stake the funds"}{" "}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>

                    {selectedOption === "deposit liquidity" && (
                      <div className="mt-4">
                        <CustomeTextInput
                          type="text"
                          state={nodeAddress}
                          setState={setNodeAddress}
                          placeholder="Deposit contract address"
                          labelName=""
                        />
                      </div>
                    )}

                    <div className="mt-4">
                      {/* Component to add Amount and show Token name */}
                      {selectedCategory !== "🏞️ NFT" &&
                        selectedCategory !== "🔗 Offchain" && (
                          <CustomeFloatAmountInput
                            // type="number"
                            state={addedAmount}
                            setState={setAddedAmount}
                            placeholder={
                              selectedCategory === "🤝 DEX"
                                ? "Minimum volume traded"
                                : selectedOption === "deposit liquidity"
                                ? "Amount to deposit per user"
                                : selectedOption === "stake/liquid stake"
                                ? "Amount to stake"
                                : "Amount per user"
                            }
                            labelName={
                              selectedCategory === "💵 DeFi" &&
                              selectedOption === "stake/liquid stake" ? (
                                blockchainLabels[selectedBlockchain]?.labelName
                              ) : selectedOption === "swap any" ? (
                                <span className="whitespace-nowrap">
                                  $ USD{" "}
                                </span>
                              ) : tokenInfoData?.name ? (
                                tokenInfoData?.name
                              ) : (
                                tokenInfoData &&
                                tokenInfoData[0] &&
                                tokenInfoData[0]?.token
                              )
                            }
                            // logoPath={blockchainLabels[selectedBlockchain]?.logoPath}
                            isDevided={true}
                            isRequiredEmpty={isRequiredEmpty}
                            tokenInfoLoading={
                              selectedOption !== "stake/liquid stake" &&
                              tokenInfoLoading
                            }
                          />
                        )}

                      {/* Show error when entered blockchain Address is not right */}
                      {validAddress &&
                        selectedCategory !== "💵 DeFi" &&
                        selectedCategory !== "🔗 Offchain" && (
                          <Error
                            message={
                              "The contract address you entered is not recognised. Please try again"
                            }
                          />
                        )}

                      {/* Only show When category is NFT */}
                      {selectedCategory === "🏞️ NFT" && (
                        <>
                          <div className="bg-bright-gray border border-border-gray rounded-2xl px-6 py-4 my-4 h-[60px] max-w-[480px]">
                            <span className="text-sm">
                              {tokenInfoData &&
                                tokenInfoData[0] &&
                                tokenInfoData[0]?.tokenFullName}
                            </span>
                          </div>
                          <CustomeInput
                            type="number"
                            state={addedAmount}
                            setState={setAddedAmount}
                            placeholder={
                              selectedOption === "mint"
                                ? "Number of NFT to mint per user"
                                : selectedOption === "buy"
                                ? "Number of NFT to buy per user"
                                : selectedOption === "hold"
                                ? "Number of NFT to hold per user"
                                : selectedOption === "list"
                                ? "Minimum list price"
                                : "Number of NFT"
                            }
                            isRequiredEmpty={isRequiredEmpty}
                            labelName={selectedOption === "list" && "ETH"}
                            // logoPath={blockchainLabels[selectedBlockchain]?.logoPath}
                            isDevided={selectedOption === "list" && true}
                            errorMessge={
                              addedAmount &&
                              Number(addedAmount) < 1 && (
                                <Error
                                  message={"Number must be  1 or above "}
                                />
                              )
                            }
                          />
                        </>
                      )}
                      {/* Simple input to add Duration */}
                      {(selectedOption === "hold" ||
                        selectedOption === "deposit liquidity" ||
                        selectedOption === "list") && (
                        <div className="mt-6">
                          <CustomeInput
                            type="number"
                            state={duration}
                            setState={setDuration}
                            placeholder={
                              selectedOption === "list"
                                ? "How long users need to list"
                                : "How long users need to hold"
                            }
                            labelName="Days"
                            isRequiredEmpty={isRequiredEmpty}
                          />
                        </div>
                      )}
                      {/* Showing info all time when Category is DEX After valoume input*/}
                      {selectedCategory === "🤝 DEX" && (
                        <div className="mt-4 flex items-center gap-3 md:gap-4 px-4 py-2.5 bg-light-blue rounded-2xl w-fit">
                          <Warning className="text-primary-blue" />
                          <span className="text-primary-blue text-3.25">
                            Volume from the time campaign starts until it ends{" "}
                          </span>
                        </div>
                      )}

                      {/* Showing input field of url for offchain*/}
                      {selectedCategory === "🔗 Offchain" && (
                        <CustomeTextInput
                          type="text"
                          state={linkToOffer}
                          setState={setLinkToOffer}
                          placeholder="Enter the destination URL"
                          labelName=""
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
              {/* Reward section */}
              <div className="my-10">
                <ToggleControl
                  name="Reward"
                  isRequire=""
                  subTitle="Set the reward once users perform the action"
                  iconName={<DollarSquare width={20} height={20} />}
                />
                <div className="ml-7 mt-6">
                  <div className="mb-2">
                    <span className="text-xs text-gray-150">
                      Reward per action
                    </span>
                  </div>
                  <CustomeFloatAmountInput
                    state={rewardAmount}
                    setState={setRewardAmount}
                    placeholder=""
                    labelName={
                      <div className="flex items-center gap-2">
                        <img src="/assets/icons/dollar.svg" alt="doller" />
                        <span>USDC</span>
                      </div>
                    }
                    isDevided={true}
                    isRequiredEmpty={isRequiredEmpty}
                    errorMessge={
                      rewardAmount && rewardAmount < 1 ? "1$ minimum" : ""
                    }
                  />

                  <div className="mb-2 mt-6">
                    <span className="text-xs text-gray-150">
                      Number max. of participants
                    </span>
                  </div>
                  <CustomeInput
                    type="number"
                    state={totalParticipant}
                    setState={setTotalParticipant}
                    placeholder=""
                    labelName=""
                    isRequiredEmpty={isRequiredEmpty}
                  />
                  <div className="mb-2 mt-4">
                    <span className="text-xs text-gray-150">
                      Total to Deposit{" "}
                    </span>
                  </div>

                  <div className="bg-bright-gray border border-border-gray rounded-2xl px-6 py-4 mb-4 max-w-[480px]">
                    <div className="flex items-center gap-2">
                      <img src="/assets/icons/dollar.svg" alt="doller" />
                      {totalParticipant && rewardAmount && (
                        <span>
                          ${" "}
                          {(
                            Number(removeCommas(totalParticipant)) *
                            Number(removeCommas(rewardAmount)) *
                            1.2
                          )?.toLocaleString("en-us")}{" "}
                          USDC
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <span className="font-bold text-xs mt-2">
                      Cost per reward
                    </span>
                    <div className="flex flex-row gap-2 text-xs">
                      <span>Number of reward max.</span>:
                      {totalParticipant && rewardAmount && (
                        <span>
                          ${" "}
                          {(
                            Number(removeCommas(totalParticipant)) *
                            Number(removeCommas(rewardAmount))
                          ).toLocaleString("en-US")}{" "}
                          USDC
                        </span>
                      )}
                    </div>
                    <div className="flex flex-row gap-2 text-xs">
                      <span>Linkko max. fees (20%)</span>:
                      {totalParticipant && rewardAmount && (
                        <span>
                          ${" "}
                          {(
                            Number(removeCommas(totalParticipant)) *
                              Number(removeCommas(rewardAmount)) *
                              1.2 -
                            Number(removeCommas(totalParticipant)) *
                              Number(removeCommas(rewardAmount))
                          ).toLocaleString("en-US")}{" "}
                          USDC
                        </span>
                      )}
                    </div>
                    <div className="flex flex-row gap-2 items-center">
                      <span className="w-32 text-xs">Creation fee</span>:
                      <div className="px-4 py-1 bg-light-blue rounded-full">
                        <div className="bg-primary-gradient bg-clip-text text-transparent text-xs">
                          $ 0 USDC
                        </div>
                      </div>
                      <div className="text-[10px] text-secondary-gray italic stroke-gray-500 line-through">
                        $ 5 USDC
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 flex items-start gap-3 md:gap-4 px-4 py-2.5 bg-light-blue rounded-2xl w-fit">
                    <RoundedClock
                      width={16}
                      height={16}
                      color="#5E91FF"
                      className="shrink-0"
                    />
                    <span className="text-primary-blue text-3.25 ">
                      Balance will be deducted after each conversion events.
                      <br /> You can withdraw your remaining deposit at anytime{" "}
                    </span>
                  </div>
                </div>
              </div>
              {/* Link Component */}
              <div className="mt-10">
                <ToggleControl
                  name="Link to Offer"
                  isRequire=""
                  subTitle="Enter the link where users can perform the action"
                  iconName={<Link />}
                />
                <div className="ml-7 mt-6">
                  <CustomeTextInput
                    type="text"
                    state={linkToOffer}
                    setState={setLinkToOffer}
                    placeholder="Enter link URL"
                    labelName=""
                    isRequiredEmpty={isRequiredEmpty}
                    errorMessge={
                      !isValidUrl
                        ? "Enter in a valid format: https://www.linkko.io/"
                        : ""
                    }
                  />
                </div>
              </div>
              {/* Details Section */}
              <div className="my-10">
                <ToggleControl
                  name="Details"
                  isRequire=""
                  subTitle="Instructions for users about your offer"
                  iconName={<Hamburger />}
                />
                {/* Editor component */}
                <div className="ml-7 mt-6">
                  <EditorComponent
                    setEditorDescription={setEditorDescription}
                    isRequiredEmpty={isRequiredEmpty}
                    editorDescription={editorDescription}
                    editorState={editorState}
                    setEditorState={setEditorState}
                    placeholder={`1. Visit Linkko Website 
2. Go to the Linkko website or open the Linkko app 
3. Connect your wallet and perform an offer`}
                  />
                </div>
              </div>

              {/* Duration section */}

              <ToggleControl
                name="Choose Duration"
                isRequire=""
                subTitle="Choose the period when users can start the action"
                iconName={<Calender />}
              />
              <div className="mt-2 ml-7 pb-10">
                <RadioInput
                  options={["Start now", "Start later"]}
                  selectedOption={startDuration}
                  setSelectedOption={setStartDuration}
                  isRequiredEmpty={isRequiredEmpty}
                />

                {startDuration === "start later" ? (
                  <CustomeDateTimeInput
                    selectedDate={selectedStartDate}
                    handleDateChange={handleDateChange}
                    isEndDate={false}
                  />
                ) : null}
                <div className="mt-2">
                  <span className="text-text-gray text-xs">End date</span>
                </div>
                <RadioInput
                  options={["Unlimited", "Preset period", "Custom dates"]}
                  selectedOption={endDuration}
                  setSelectedOption={setEndDuration}
                  isRequiredEmpty={isRequiredEmpty}
                />

                {endDuration === "preset period" && (
                  <div className="border border-border-gray rounded-2xl max-w-[480px] mb-40">
                    <div className="px-6 py-2.5 flex justify-between">
                      <div className="flex w-full">
                        <input
                          type="number"
                          className=" w-full text-start border-transparent focus:ring-0 focus:border-transparent text-sm  placeholder:text-secondary-gray"
                          placeholder="Duration"
                          value={presentPeriod}
                          onChange={(e) => setPresentPeriod(e.target.value)}
                          onKeyDown={preventNegative}
                          onWheel={(e) => e.target.blur()}
                        />
                      </div>

                      <div className="flex gap-2 items-center">
                        <TextSelect
                          options={durationData}
                          value={selectedDuration}
                          onChange={(value) => setSelectedDurations(value)}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {endDuration === "custom dates" && (
                  <CustomeDateTimeInput
                    selectedDate={selectedEndDate}
                    handleDateChange={handleEndDateChange}
                    isEndDate={true}
                  />
                )}
              </div>
            </div>
            {/* Preview thing of Right side */}
            <PreviewComponent
              selectedCategory={selectedCategory}
              selectedOption={selectedOption}
              selectedBlockchain={selectedBlockchain}
              duration={duration}
              addedAmount={addedAmount}
              rewardAmount={rewardAmount}
              totalParticipant={totalParticipant}
              totalDeposit={totalDeposit}
              startDuration={startDuration}
              selectedDate={selectedStartDate}
              selectedEndDate={selectedEndDate}
              handleRequireFieldCheck={handleRequireFieldCheck}
              isRequiredEmpty={isRequiredEmpty}
              tokenInfoData={tokenInfoData}
              publishOfferLoading={publishOfferLoading}
              selectedDEX={selectedDEX}
              setOpenOfferPreview={setOpenOfferPreview}
              endDuration={endDuration}
            />
          </div>

          {/* Modal to show offer preview in desktop + Mobile view */}
          <PreviewModal
            open={openOfferPreview}
            onClose={() => setOpenOfferPreview(false)}
            selectedCategory={selectedCategory}
            selectedBlockchain={selectedBlockchain}
            rewardAmount={rewardAmount}
            totalParticipant={totalParticipant}
            selectedEndDate={selectedEndDate}
            linkToOffer={linkToOffer}
            offerName={actionType + " " + addedAmount}
            actionType={actionType}
            blockchainAddress={blockchainAddress}
            addedAmount={addedAmount}
            tokenName={tokenInfoData[0]?.token || tokenInfoData?.name}
            duration={duration}
            editorDescription={editorDescription}
            tokenInfoData={tokenInfoData}
            totalReward={(
              Number(removeCommas(totalParticipant)) *
              Number(removeCommas(rewardAmount))
            ).toLocaleString("en-US")}
          />

          <CompanyLogoModal
            open={logoModal}
            setOpenLogoModal={setOpenLogoModal}
            onClose={() => {
              setOpenLogoModal(false);
            }}
          />
        </>
      ) : (
        <VerifyOffer
          setOpenVerifyScreen={setOpenVerifyScreen}
          setUpdateOffer={setUpdateOffer}
          totalDeposit={totalDeposit}
          rewardAmount={rewardAmount}
          totalParticipant={totalParticipant}
          offerId={offerId}
          isVisible={true}
        />
      )}
    </div>
  );
};
export default CreateNewOffer;
