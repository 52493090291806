import { useEffect, useState } from "react";
import { useAuthPasswordLess } from "../../contexts/AuthPasswordLessContext";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import LoadingScreen from "../LoadingScreen";

const ParternerLogin = () => {
  const currentYear = new Date().getFullYear();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [errorMessage, setErroMessage] = useState("");
  const [disableLogin, setDisableLogin] = useState(false);
  const { refereshAuthorise, isAuthorise, callGetClient, storeGetClientData } =
    useAuthPasswordLess();
  const history = useHistory();
  const { pathname } = useLocation();
  useEffect(() => {
    if (isAuthorise) {
      callGetClient();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorise]);

  useEffect(() => {
    if (storeGetClientData?.clientId) {
      history.push("/");
      setDisableLogin(false);
    }
  }, [storeGetClientData?.clientId]);
  const handleFormSubmit = async () => {
    localStorage.setItem("path", pathname);
    setDisableLogin(true);

    try {
      await fetch(`${process.env.REACT_APP_BACKEND_URL}/member_login`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        redirect: "follow",
        referrerPolicy: "no-referrer",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      })
        .then((res) => res.json())
        .then(async ({ data, message, success }) => {
          if (success) {
            localStorage.setItem("sessionJwtToken", data?.sessionJwt);
            await refereshAuthorise();
          } else {
            setErroMessage(message);
            setDisableLogin(false);
          }
        });
    } catch (err) {
      console.log(err);
      history.push("/partners/login");
    }
  };

  return (
    <>
      <div className="w-full">
        <img
          src="/assets/welcome-banner.svg"
          alt="welcome banner"
          className="h-screen w-full object-cover md:visible"
        />
        <img
          src="/assets/Welcome-screen-mobile.svg"
          alt="welcome banner mobile"
          className="h-screen w-full object-cover md:hidden absolute top-0"
        />
        <div className="h-full flex justify-center items-center  absolute top-0 left-0 right-0">
          {disableLogin ? (
            <LoadingScreen />
          ) : (
            <div className="border border-border-gray rounded-3xl px-[42px] py-10 bg-white mx-7 md:mx-0">
              <div className="flex justify-center flex-col items-center">
                <img src="/assets/linkko.svg" alt="linkko logo" />
                <span className="text-base text-gray-150 mt-2">
                  Turn attention into income
                </span>
              </div>
              <div className="mt-14">
                <span className="text-base font-medium text-black-100">
                  Email
                </span>
                <input
                  type="email"
                  placeholder="Enter Your Email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={(e) => {
                    if (e && e.key === "Enter") {
                      e.preventDefault(); // Prevent the default form submission behavior
                      handleFormSubmit();
                    }
                  }}
                  className="mt-4 mb-6 rounded-lg w-full px-6 py-3 focus:ring-0 focus:ring-border-gray border-border-gray text-gray-500 text-sm"
                />
                <div>
                  <span className="text-base font-medium text-black-100">
                    Password
                  </span>
                </div>
                <div className="relative">
                  <input
                    type={!passwordShown ? "password" : "text"}
                    placeholder="Enter Your Password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={(e) => {
                      if (e && e.key === "Enter") {
                        e.preventDefault(); // Prevent the default form submission behavior
                        handleFormSubmit();
                      }
                    }}
                    className="mt-4 rounded-lg w-full px-6 py-3 focus:ring-0 focus:ring-border-gray border-border-gray text-gray-500 text-sm"
                  />
                  <img
                    src={
                      passwordShown
                        ? "/assets/icons/eye.svg"
                        : "/assets/icons/eye-slash.svg"
                    }
                    alt="show/hide"
                    className="absolute right-2 top-7 cursor-pointer"
                    onClick={() => setPasswordShown(!passwordShown)}
                  />
                </div>
              </div>
              <button
                className="bg-primary-blue rounded-3xl px-6 py-3.5 mt-4 text-center cursor-pointer w-full sm:w-[388px]"
                onClick={handleFormSubmit}
                disabled={disableLogin}
              >
                <span className="text-white font-bold text-tiny ">Login</span>
              </button>
              <div className="w-60 truncate mt-2">
                <span className="text-xs text-red-200">{errorMessage}</span>
              </div>
            </div>
          )}
        </div>
        <div></div>
        <div className="absolute bottom-10 w-full flex justify-center">
          <span className="text-gray-100 text-3.25 font-medium">
            © Linkko {currentYear}. All right reserved
          </span>
        </div>
      </div>
    </>
  );
};
export default ParternerLogin;
