const Input = ({ state, setState, iconComponent }) => {
  return (
    <div className="text-primary-blue mt-4 rounded-lg border border-border-gray focus:ring-0 focus:outline-none focus:border-primary-blue focus:border-opacity-25 w-full flex items-center px-6">
      {iconComponent}
      <input
        type="text"
        className="focus:ring-0 focus:outline-none border-none  py-3 w-full text-black text-sm font-medium"
        value={state}
        onChange={(e) => setState(e.target.value)}
      />
    </div>
  );
};
export default Input;
