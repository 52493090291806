import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import {
  Arrow135,
  CloseIcon,
  DesktopIcon,
  LeftArrow,
  MobileIcon,
  ProfileIcon,
  RefreshGradiantIcon,
  RoundedClock,
  RoundedQuestionMark,
  VerifiedIcon,
} from "../../icons/icons";
import { useAuthPasswordLess } from "../../contexts/AuthPasswordLessContext";
import { inputBlackchainOptions } from "../../utils/constant";
import { calcuateDateDifference } from "../../utils/helper";
import HTMLReactParser from "html-react-parser/lib/index";

const PreviewModal = ({
  open,
  onClose,
  selectedCategory,
  selectedBlockchain,
  rewardAmount,
  totalParticipant,
  selectedEndDate,
  linkToOffer,
  offerName,
  actionType,
  blockchainAddress,
  addedAmount,
  tokenName,
  duration,
  editorDescription,
  tokenInfoData,
  totalReward,
}) => {
  const [isMobilePreview, setIsMobilePreview] = useState(false);
  const { storeGetClientData } = useAuthPasswordLess();
  const removeCommas = (str) => {
    return str?.replace(/,/g, ""); // Replaces all commas with nothing
  };
  const selected = inputBlackchainOptions?.find(
    (option) => option?.value === selectedBlockchain?.toLowerCase()
  );
  const [showNext, setShowNext] = useState(false);
  const blockChainName =
    selectedBlockchain === "Polygon"
      ? "polygon"
      : selectedBlockchain === "Optimism"
      ? "op"
      : selectedBlockchain === "Arbitrum"
      ? "arbitrum"
      : selectedBlockchain === "Base"
      ? "base"
      : selectedBlockchain === "Linea"
      ? "linea"
      : selectedBlockchain === "Manta"
      ? "manta"
      : selectedBlockchain === "Avalanche"
      ? "avaxc"
      : selectedBlockchain?.toLocaleLowerCase()?.slice(0, 3);
  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25 " />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`w-full ${
                    isMobilePreview ? " max-w-[423px]" : "max-w-[1200px]"
                  } transform  relative rounded-3xl py-10 px-6 bg-white text-left align-middle shadow-xl transition-all`}
                >
                  <Dialog.Title
                    as="h3"
                    className="flex w-full justify-center items-center text-lg font-medium leading-6 text-gray-900"
                  >
                    <div className="cursor-pointer">
                      <img
                        src="/assets/icons/close.svg"
                        alt="close"
                        className="absolute top-7 right-8 h-8 w-8 z-10"
                        onClick={onClose}
                      />
                    </div>
                    <div className="flex flex-col w-full relative">
                      <div className="flex items-center gap-4 justify-center">
                        <div className="p-2 bg-light-blue rounded-lg">
                          <img
                            src="/assets/icons/eye.svg"
                            alt="building icon"
                            className="w-5 h-5"
                          />
                        </div>
                        <span className="font-semibold text-2xl">Preview</span>
                      </div>
                      <div className="flex flex-col gap-4 justify-center mt-8">
                        <span className="text-3.75 text-center text-gray-100">
                          Preview ads on:
                        </span>
                        <div className="bg-bright-blue px-4 py-2 rounded-3xl flex gap-4 w-fit mx-auto">
                          <button
                            className={`px-6 py-2.5  rounded-full text-3.75 flex gap-2 ${
                              !isMobilePreview
                                ? "text-white bg-primary-blue"
                                : "text-secondary-gray bg-transparent"
                            }`}
                            onClick={() => setIsMobilePreview(false)}
                          >
                            <DesktopIcon />
                            Desktop
                          </button>
                          <button
                            className={`px-6 py-2.5 rounded-full  ${
                              isMobilePreview
                                ? "text-white bg-primary-blue"
                                : "text-secondary-gray bg-transparent"
                            } text-3.75 flex gap-2`}
                            onClick={() => setIsMobilePreview(true)}
                          >
                            <MobileIcon />
                            Mobile
                          </button>
                        </div>
                      </div>
                      <div className="p-px bg-primary-gradient rounded-lg mt-8">
                        <div className="bg-white px-4 py-6 rounded-lg">
                          <div
                            className={`grid grid-cols-5 gap-2 ${
                              isMobilePreview ? "hidden" : "block"
                            }`}
                          >
                            <div className="py-2 flex gap-4">
                              <div className="w-12 h-12 rounded-full items-center flex justify-center bg-sky-50 relative">
                                <img
                                  src={
                                    storeGetClientData?.companyLogo ||
                                    "/assets/icons/linkko.svg"
                                  }
                                  alt="logo"
                                  className="object-contain rounded-full"
                                />
                                <div className="bg-primary-gradient p-px absolute -bottom-0 -right-1 rounded-full group ">
                                  <div className=" bg-white w-5 h-5 rounded-full flex justify-center items-center">
                                    <img
                                      src={`/assets/icons/${selected?.logo}`}
                                      alt=""
                                      className="w-4 h-4"
                                    />

                                    <div className="group-hover:visible invisible absolute text-white bg-black-100 rounded-lg top-5 px-2 py-1 text-[11px]">
                                      {selected.label}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="flex flex-col gap-2">
                                <div className="flex gap-1 items-center">
                                  <span className="text-sm font-medium">
                                    {storeGetClientData?.companyName}
                                  </span>
                                  <VerifiedIcon />
                                </div>
                                <span className="bg-sky-50 text-primary-blue px-2 py-1 w-fit rounded-lg text-3.25 font-medium">
                                  {selectedCategory}
                                </span>
                              </div>
                            </div>
                            <div className="">
                              <div>
                                <span className="text-sm font-medium">
                                  {offerName}
                                </span>
                                <div
                                  className={`py-1 px-3 bg-[#FFF7E6] text-[#FCAF03] rounded-full w-fit text-3.25 font-medium mt-2`}
                                >
                                  Not Started
                                </div>
                              </div>
                            </div>

                            <div className="flex gap-2 items-center justify-start h-full">
                              <div className="flex gap-2 items-center text-primary-blue">
                                <img src="/assets/icons/dollar.svg" alt="" />{" "}
                                <span className="text-sm text-black">
                                  $ {rewardAmount}
                                </span>
                                <span className="text-sm text-black">USDC</span>
                              </div>
                            </div>

                            <div className="my-auto">
                              {Number(
                                removeCommas(
                                  totalParticipant?.toLocaleString("en-US")
                                ) > 0
                              ) &&
                              Number(
                                removeCommas(
                                  totalReward?.toLocaleString("en-us")
                                )
                              ) ? (
                                <>
                                  <div className="w-fit">
                                    <div className="flex gap-2 items-center">
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setShowNext((prev) => !prev);
                                        }}
                                      >
                                        <LeftArrow />
                                      </button>

                                      {showNext ? (
                                        <div className="flex gap-2 items-center">
                                          <img
                                            src="assets/icons/dollar.svg"
                                            alt=""
                                            className="w-4 h-4 object-contain"
                                          />
                                          <span className="text-xs text-gray-150 font-medium w-32 truncate ">
                                            {/* rowData.distributedReward */}0 /{" "}
                                            {totalReward}{" "}
                                            {/* {rowData.offerRewardTokenName} */}
                                            USDC
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="flex gap-2 items-center">
                                          <ProfileIcon className="text-primary-blue w-4 h-4" />
                                          <span className="text-xs text-gray-150 font-medium w-32 truncate">
                                            {/* {rowData.currentEntry} */}0 /{" "}
                                            {totalParticipant} Participants
                                          </span>{" "}
                                        </div>
                                      )}
                                      <button
                                        className="rotate-180"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setShowNext((prev) => !prev);
                                        }}
                                      >
                                        <LeftArrow />
                                      </button>
                                    </div>
                                    <div className="py-1 mt-2">
                                      <div className="h-0.5 md:h-1.5 w-[170px] bg-blue-100 rounded-full overflow-hidden mx-auto">
                                        <div
                                          className="h-full bg-primary-gradient rounded-full"
                                          style={{
                                            width: `${
                                              showNext
                                                ? (0 /
                                                    Number(
                                                      removeCommas(
                                                        totalReward?.toLocaleString(
                                                          "en-US"
                                                        )
                                                      )
                                                    )) *
                                                  100
                                                : (0 /
                                                    Number(
                                                      removeCommas(
                                                        totalParticipant?.toLocaleString(
                                                          "en-US"
                                                        )
                                                      )
                                                    )) *
                                                  100
                                            }%`,
                                            transition: "width 1s linear",
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {Number(
                                    removeCommas(
                                      totalParticipant?.toLocaleString("en-US")
                                    ) > 0
                                  ) ? (
                                    <>
                                      <div className="flex gap-2 items-center">
                                        <span className="text-xs text-gray-150 font-medium ">
                                          {/* {rowData.currentEntry} */}0 /{" "}
                                          {totalParticipant} Participants
                                        </span>
                                      </div>
                                      <div className="py-1 w-full mt-2">
                                        <div className="h-0.5 md:h-1.5 w-[180px] bg-blue-100 rounded-full overflow-hidden">
                                          {/* <div
                                            className="h-full bg-primary-gradient rounded-full"
                                            style={{
                                              width: `${
                                                // rowData.currentEntry
                                                (0 / totalParticipant) * 100
                                              }%`,
                                              transition: "width 1s linear",
                                            }}
                                          ></div> */}
                                        </div>
                                      </div>
                                    </>
                                  ) : Number(
                                      removeCommas(
                                        totalParticipant?.toLocaleString(
                                          "en-US"
                                        )
                                      ) > 0
                                    ) ? (
                                    <>
                                      <div className="flex gap-2 items-center">
                                        <img
                                          src="assets/icons/dollar.svg"
                                          alt=""
                                          className="w-4 h-4 object-contain"
                                        />

                                        <span className="text-xs text-gray-150 font-medium ">
                                          {/* {rowData.distributedReward}  */}0
                                          / {totalReward}{" "}
                                          {/* {rowData.offerRewardTokenName} */}{" "}
                                          USDC
                                        </span>
                                      </div>
                                      <div className="py-1 w-full mt-2">
                                        <div className="h-0.5 md:h-1.5 w-[180px] bg-blue-100 rounded-full overflow-hidden">
                                          <div
                                            className="h-full bg-primary-gradient rounded-full"
                                            style={{
                                              width: `${
                                                // rowData.distributedReward
                                                (0 /
                                                  Number(
                                                    removeCommas(
                                                      rewardAmount?.toLocaleString(
                                                        "en-US"
                                                      )
                                                    )
                                                  )) *
                                                100
                                              }%`,
                                              transition: "width 1s linear",
                                            }}
                                          ></div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <span className="text-sm font-medium">
                                      unlimited
                                    </span>
                                  )}
                                </>
                              )}
                            </div>
                            <div className="my-auto ml-5">
                              <span className="text-sm font-medium">
                                {selectedEndDate
                                  ? calcuateDateDifference(selectedEndDate)
                                  : "unlimited"}
                              </span>
                            </div>
                          </div>
                          <div
                            className={`${
                              isMobilePreview ? "block" : "hidden"
                            }`}
                          >
                            <div className="flex gap-4">
                              <div className="w-12 h-12 rounded-full items-center flex justify-center bg-sky-50 relative">
                                <img
                                  src={
                                    storeGetClientData?.companyLogo ||
                                    "/assets/icons/linkko.svg"
                                  }
                                  alt="logo"
                                  className="object-contain rounded-full"
                                />
                                <div className="bg-primary-gradient p-px absolute -bottom-0 -right-1 rounded-full group">
                                  <div className=" bg-white w-5 h-5 rounded-full flex justify-center items-center">
                                    <img
                                      src={`/assets/icons/${selected?.logo}`}
                                      alt=""
                                      className="w-3 h-3 object-contain"
                                    />
                                    <div className="group-hover:visible invisible absolute text-white bg-black-100 rounded-lg top-5 px-2 py-1 text-[11px]">
                                      {selected?.label}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="">
                                <div className="flex gap-2">
                                  <div className="flex gap-1 items-center">
                                    <span className="text-sm font-medium">
                                      {storeGetClientData?.companyName}
                                    </span>
                                    <VerifiedIcon />
                                  </div>
                                  <div className="flex gap-2 items-center py-1 px-2 bg-sky-50 rounded-lg">
                                    <img
                                      src="/assets/icons/dollar.svg"
                                      alt="token"
                                      className="w-4 h-4 object-contain"
                                    />

                                    <span className="text-3.25 font-medium text-primary-blue">
                                      $ {rewardAmount}
                                      &nbsp;USDC
                                    </span>
                                  </div>
                                </div>
                                <div className="mt-2">
                                  {Number(
                                    removeCommas(
                                      totalParticipant?.toLocaleString("en-US")
                                    ) > 0
                                  ) &&
                                  Number(
                                    removeCommas(
                                      totalReward?.toLocaleString("en-us")
                                    )
                                  ) ? (
                                    <>
                                      <div className="w-fit">
                                        <div className="flex gap-2 items-center">
                                          <button
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setShowNext((prev) => !prev);
                                            }}
                                          >
                                            <LeftArrow />
                                          </button>

                                          {showNext ? (
                                            <div className="flex gap-2 items-center">
                                              <img
                                                src="assets/icons/dollar.svg"
                                                alt=""
                                                className="w-4 h-4 object-contain"
                                              />
                                              <span className="text-xs text-gray-150 font-medium w-32 truncate ">
                                                {/* rowData.distributedReward */}
                                                0 / {totalReward}{" "}
                                                {/* {rowData.offerRewardTokenName} */}
                                                USDC
                                              </span>
                                            </div>
                                          ) : (
                                            <div className="flex gap-2 items-center">
                                              <ProfileIcon className="text-primary-blue w-4 h-4" />
                                              <span className="text-xs text-gray-150 font-medium w-32 truncate">
                                                {/* {rowData.currentEntry} */}0
                                                / {totalParticipant}{" "}
                                                Participants
                                              </span>{" "}
                                            </div>
                                          )}
                                          <button
                                            className="rotate-180"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setShowNext((prev) => !prev);
                                            }}
                                          >
                                            <LeftArrow />
                                          </button>
                                        </div>
                                        <div className="py-1 mt-2">
                                          <div className="h-0.5 md:h-1.5 w-[170px] bg-blue-100 rounded-full overflow-hidden mx-auto">
                                            <div
                                              className="h-full bg-primary-gradient rounded-full"
                                              style={{
                                                width: `${
                                                  showNext
                                                    ? (0 /
                                                        Number(
                                                          removeCommas(
                                                            totalReward?.toLocaleString(
                                                              "en-US"
                                                            )
                                                          )
                                                        )) *
                                                      100
                                                    : (0 /
                                                        Number(
                                                          removeCommas(
                                                            totalParticipant?.toLocaleString(
                                                              "en-US"
                                                            )
                                                          )
                                                        )) *
                                                      100
                                                }%`,
                                                transition: "width 1s linear",
                                              }}
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {totalParticipant ? (
                                        <>
                                          <div className="flex gap-2 items-center">
                                            <span className="text-xs text-gray-150 font-medium ">
                                              {/* {rowData.currentEntry} */}0 /{" "}
                                              {totalParticipant} Participants
                                            </span>
                                          </div>
                                          <div className="py-1 w-full mt-2">
                                            <div className="h-0.5 md:h-1.5 w-[180px] bg-blue-100 rounded-full overflow-hidden">
                                              <div
                                                className="h-full bg-primary-gradient rounded-full"
                                                style={{
                                                  width: `${
                                                    // rowData.distributedReward
                                                    (0 /
                                                      Number(
                                                        removeCommas(
                                                          totalParticipant?.toLocaleString(
                                                            "en-US"
                                                          )
                                                        )
                                                      )) *
                                                    100
                                                  }%`,
                                                  transition: "width 1s linear",
                                                }}
                                              ></div>
                                            </div>
                                          </div>
                                        </>
                                      ) : totalReward ? (
                                        <>
                                          <div className="flex gap-2 items-center">
                                            <img
                                              src="assets/icons/dollar.svg"
                                              alt=""
                                              className="w-4 h-4 object-contain"
                                            />

                                            <span className="text-xs text-gray-150 font-medium ">
                                              {/* {rowData.distributedReward}  */}
                                              0 / {totalReward}{" "}
                                              {/* {rowData.offerRewardTokenName} */}{" "}
                                              USDC
                                            </span>
                                          </div>
                                          <div className="py-1 w-full mt-2">
                                            <div className="h-0.5 md:h-1.5 w-[180px] bg-blue-100 rounded-full overflow-hidden">
                                              <div
                                                className="h-full bg-primary-gradient rounded-full"
                                                style={{
                                                  width: `${
                                                    // rowData.distributedReward
                                                    (0 /
                                                      Number(
                                                        removeCommas(
                                                          rewardAmount?.toLocaleString(
                                                            "en-US"
                                                          )
                                                        )
                                                      )) *
                                                    100
                                                  }%`,
                                                  transition: "width 1s linear",
                                                }}
                                              ></div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <span className="text-sm font-medium">
                                          unlimited
                                        </span>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="mt-6 flex flex-col gap-2">
                              {" "}
                              <span className="text-sm font-medium">
                                {offerName}
                              </span>
                              <div className="flex gap-2 items-center">
                                <span className="bg-sky-50 text-primary-blue px-2 py-1 w-fit rounded-lg text-3.25 font-medium">
                                  {selectedCategory}
                                </span>
                                <div
                                  className={`py-1 px-3 bg-bright-gray text-gray-150 rounded-full w-fit text-3.25 font-medium`}
                                >
                                  Not Started
                                </div>
                                <span className="text-sm font-medium truncate">
                                  {selectedEndDate
                                    ? calcuateDateDifference(selectedEndDate)
                                    : "unlimited"}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-between mt-5">
                            <div
                              className={`${
                                isMobilePreview ? "gap-2" : "gap-4"
                              } flex `}
                            >
                              <a
                                href={linkToOffer}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <button
                                  className={`bg-primary-gradient py-3 ${
                                    isMobilePreview ? "px-5" : "px-8"
                                  } rounded-full text-white text-3.25 font-medium flex items-center gap-1 whitespace-nowrap hover:shadow-primaryShadow`}
                                >
                                  Link to offer
                                  <Arrow135 />
                                </button>
                              </a>
                              {selectedCategory !== "🔗 Offchain" && (
                                <>
                                  <button className="bg-primary-gradient p-px rounded-full h-fit">
                                    <div
                                      className={` rounded-full text-3.25 font-medium flex items-center gap-1 hover:shadow-primaryShadow bg-white ${
                                        isMobilePreview
                                          ? "px-10 py-2.5"
                                          : "px-14 py-3"
                                      }`}
                                    >
                                      <span className="bg-clip-text bg-primary-gradient text-transparent">
                                        Verify
                                      </span>
                                      <RefreshGradiantIcon />
                                    </div>
                                  </button>

                                  <div className="group relative my-auto flex-shrink-0">
                                    <RoundedQuestionMark className="text-primary-blue" />
                                    <span
                                      className={`group-hover:visible invisible absolute -top-11 z-50 px-3 py-2 text-[11px] text-white bg-black-100 rounded-lg  w-40 leading-4 ${
                                        isMobilePreview
                                          ? "right-3.5 rounded-br-none"
                                          : "left-4 rounded-bl-none"
                                      }`}
                                    >
                                      Update the requirement / action you have
                                      done
                                    </span>
                                  </div>
                                </>
                              )}
                            </div>
                            {selectedCategory === "🔗 Offchain" && (
                              <div className="flex items-center gap-2 px-4 py-3 bg-sky-50 rounded-2xl">
                                <RoundedClock
                                  width={16}
                                  height={16}
                                  color="#5E91FF"
                                  className="shrink-0"
                                />
                                <span className="text-primary-blue text-3.25 truncate">
                                  Offchain verification can take up to 72h to
                                  reflect
                                </span>
                              </div>
                            )}
                          </div>

                          <div
                            className={`flex ${
                              isMobilePreview ? "flex-col" : "flex-row"
                            }  w-full mt-6 gap-6`}
                          >
                            <div
                              className={` ${
                                !isMobilePreview &&
                                "border rounded-lg border-border-gray p-6"
                              } w-full h-fit`}
                            >
                              <span className="text-base font-bold">
                                Action
                              </span>
                              <div className="mt-4">
                                <div className="flex gap-2 items-center">
                                  <div className="w-6 h-6 rounded-full flex justify-center items-center bg-bright-gray text-gray-100">
                                    <CloseIcon />
                                  </div>
                                  <span className="text-sm font-medium capitalize">
                                    {actionType}
                                  </span>

                                  {actionType !== "Sign up" && (
                                    <a
                                      href={`https://www.oklink.com/${blockChainName}/address/${blockchainAddress}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <div className="flex gap-1 items-center group">
                                        {(tokenInfoData[0]?.tokenImage ||
                                          tokenInfoData?.metadata?.image) && (
                                          <img
                                            src={`${
                                              tokenInfoData[0]?.tokenImage ||
                                              tokenInfoData?.metadata?.image
                                            }`}
                                            alt=""
                                            className="w-4 h-4"
                                          />
                                        )}
                                        <span className="text-sm font-medium">
                                          {actionType !== "List" && addedAmount}{" "}
                                          {actionType === "Swap any"
                                            ? "USD"
                                            : tokenName}
                                        </span>

                                        {blockchainAddress && (
                                          <div className="text-primary-blue group-hover:scale-150">
                                            <Arrow135 />
                                          </div>
                                        )}
                                      </div>
                                    </a>
                                  )}
                                  {actionType === "Sign up" && (
                                    <a
                                      href={linkToOffer}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <div className="flex gap-1 items-center group">
                                        <img
                                          src={storeGetClientData?.companyLogo}
                                          alt="currency icon"
                                          className="w-4 h-4"
                                        />
                                        <span className="text-sm font-medium">
                                          to
                                        </span>
                                        <span className="text-sm font-medium">
                                          {storeGetClientData?.companyName}
                                        </span>

                                        <div className="text-primary-blue group-hover:scale-150">
                                          <Arrow135 />
                                        </div>
                                      </div>
                                    </a>
                                  )}

                                  {actionType === "List" && (
                                    <span className="text-sm font-medium">
                                      for &nbsp;{parseInt(addedAmount)} ETH{" "}
                                    </span>
                                  )}
                                  <div className="flex gap-1 items-center">
                                    {(actionType === "Hold" ||
                                      actionType === "Deposit liquidity" ||
                                      actionType === "List") && (
                                      <>
                                        <span className="text-sm font-medium">
                                          for
                                        </span>
                                        <span className="text-sm font-medium">
                                          {duration} days
                                        </span>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`${
                                !isMobilePreview &&
                                "border rounded-lg border-border-gray p-6"
                              } w-full h-fit`}
                            >
                              <span className="text-base font-bold">
                                Details
                              </span>

                              <div className="mt-4">
                                <div className="overflow-auto max-h-40 scrollbar-thin scrollbar-thumb-primary-gradiant scrollbar-track-sky-50 scrollbar-thumb-rounded  scrollbar-track-rounded-full text-sm">
                                  {HTMLReactParser(
                                    editorDescription &&
                                      editorDescription?.editorContent?.replace(
                                        /\\n|\n/g,
                                        "<br/>"
                                      ),
                                    {
                                      // Modify rendering behavior to underline links
                                      replace: (domNode) => {
                                        if (domNode.name === "a") {
                                          // Apply inline style to underline and color links
                                          return (
                                            <a
                                              href={domNode.attribs.href}
                                              style={{
                                                textDecoration: "underline",
                                              }}
                                            >
                                              {domNode.children.map((child) =>
                                                HTMLReactParser(child.data)
                                              )}
                                            </a>
                                          );
                                        }
                                      },
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Title>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
export default PreviewModal;
