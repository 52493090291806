import "./Datepicker.css";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomeDateTimeInput = ({
  selectedDate,
  handleDateChange,
  isEndDate,
}) => {
  return (
    <div className="border border-border-gray rounded-lg max-w-[480px] px-4 py-[9px] flex justify-between items-center">
      <div className="flex gap-2 xl:gap-4">
        <span className="text-primary-blue text-sm p-2 bg-light-blue rounded-lg">
          <ReactDatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            showTimeSelect={false} // Disable time selection for datepicker
            dateFormat="EEE, dd MMM yyyy"
            minDate={isEndDate ? new Date(selectedDate) + 3 : new Date()}
            className="text-primary-blue border-none rounded px-2 py-0 bg-light-blue text-sm w-32 xl:w-36 cursor-pointer"
          />{" "}
        </span>
        <span className="text-primary-blue bg-light-blue text-sm p-2 rounded-lg">
          <ReactDatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            timeCaption=""
            dateFormat="h:mm aa"
            className="text-primary-blue border-none rounded px-2 py-0 bg-light-blue text-sm w-[76px] cursor-pointer"
          />
        </span>
      </div>
      <div className="flex gap-2 items-center">
        <img
          src="/assets/icons/input_website.svg"
          alt="world"
          className="w-4 h-4 object-contain"
        />
        <span className="text-sm whitespace-nowrap">UTC time</span>
      </div>
    </div>
  );
};
export default CustomeDateTimeInput;
