import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useAuthPasswordLess } from "../../contexts/AuthPasswordLessContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import TagList from "../Setting/TagList";
import { LoaderIcon } from "../../icons/icons";

const LoginFlowCompanyInfoModal = ({
  open,
  setOpenLoginFlowCompanyInfoModal,
}) => {
  const { callGetClient, storeGetClientData } = useAuthPasswordLess();
  const [companyName, setCompanyName] = useState(
    storeGetClientData?.companyName || ""
  );

  const rangeData = ["1-10", "10-100", "100-1000", "1000+"];
  const [companySize, setCompanySize] = useState(
    storeGetClientData?.companySize || null
  );
  const [error, setError] = useState("");
  const [updateClientLoading, setUpdateClientLoading] = useState(false);
  const [addClientLoading, setAddClientLoading] = useState(false);
  const sessionJwtToken = localStorage.getItem("sessionJwtToken");
  const [selected, setSelected] = useState(
    storeGetClientData?.companyTags || []
  );

  const history = useHistory();

  const handleSubmit = () => {
    if (companyName === "") {
      setError("Please fill company name");
    } else {
      callUpdateClient();
    }
  };
  // call add_client to save company logo and company url

  const callAddClient = async () => {
    setAddClientLoading(true);
    const formData = new FormData();
    formData.append("company_name", companyName);
    formData.append("company_size", companySize);
    formData.append("company_tags", selected);

    // Append the File object to the FormData

    await fetch(`${process.env.REACT_APP_BACKEND_URL}/add_client`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: formData,
      headers: {
        Authorization: sessionJwtToken,
      },
    })
      .then((res) => res.json())
      .then(({ success, message }) => {
        if (success) {
          callGetClient();
          history.push("/offers-overview");
          setAddClientLoading(false);
          setOpenLoginFlowCompanyInfoModal(false);
        } else {
          setOpenLoginFlowCompanyInfoModal(true);
          setAddClientLoading(false);
          setError(message);
        }
      })
      .catch((err) => {
        console.log(err);
        setAddClientLoading(false);
        setOpenLoginFlowCompanyInfoModal(true);
      });
  };

  const callUpdateClient = async () => {
    if (storeGetClientData === null) {
      callAddClient();
    } else {
      setUpdateClientLoading(true);
      const formData = new FormData();
      formData.append("client_id", storeGetClientData?.clientId);
      formData.append("company_name", companyName);
      formData.append("company_size", companySize);
      formData.append("company_tags", selected);

      await fetch(`${process.env.REACT_APP_BACKEND_URL}/update_client`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: formData,
        headers: {
          Authorization: sessionJwtToken,
        },
      })
        .then((res) => res.json())
        .then(({ success, message }) => {
          if (success) {
            callGetClient();
            setOpenLoginFlowCompanyInfoModal(false);
            setUpdateClientLoading(false);
            history.push("/offers-overview");
          } else {
            setError(message);
            setOpenLoginFlowCompanyInfoModal(true);
            setUpdateClientLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setUpdateClientLoading(false);
        });
    }
  };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[999]" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25 " />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-150 transform overflow-hidden relative rounded-2xl p-20  bg-white text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="flex w-full justify-center items-center text-lg font-medium leading-6 text-gray-900"
                >
                  <div className="flex flex-col w-full">
                    <div className="flex items-center gap-4 justify-center">
                      <div className="p-2 bg-light-blue rounded-lg">
                        <img
                          src="/assets/icons/building.svg"
                          alt="building icon"
                        />
                      </div>
                      <span className="font-semibold text-[26px]">
                        Company Info
                      </span>
                    </div>

                    <div className="mt-10">
                      <span className="font-medium text-base">
                        Company Name
                      </span>
                      <input
                        type="email"
                        placeholder="Enter Your Company Name"
                        required
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        className="rounded-lg border border-secondary-gray border-opacity-20 px-6 py-3 text-sm font-medium focus:ring-0 focus:outline-none focus:border-primary-blue focus:border-opacity-25 w-full mt-2 placeholder:text-secondary-gray"
                      />
                      <div className="mt-4">
                        <span className="font-medium text-base">
                          Company Tags
                        </span>
                        <TagList
                          selected={selected}
                          setSelected={setSelected}
                        />
                      </div>

                      <div className="my-4">
                        <span className="font-medium text-base">
                          Company Size
                        </span>
                        <div className="mt-2 flex gap-2">
                          {rangeData?.map((data, index) => (
                            <div
                              className={`border px-6 py-3 text-sm w-fit rounded-full cursor-pointer ${
                                companySize === data && "bg-gray-200"
                              }`}
                              key={index}
                              onClick={() => {
                                setCompanySize(data);
                              }}
                            >
                              {data}
                            </div>
                          ))}
                        </div>
                      </div>

                      <button
                        className="bg-primary-blue text-white rounded-3xl font-bold w-full mt-5 py-3 text-sm flex items-center justify-center gap-4"
                        onClick={handleSubmit}
                        disabled={updateClientLoading || addClientLoading}
                      >
                        Continue
                        {updateClientLoading || addClientLoading ? (
                          <div className="flex items-center justify-center animate-spin">
                            <LoaderIcon />
                          </div>
                        ) : (
                          ""
                        )}
                      </button>
                      {error && (
                        <div className="w-60 truncate mt-2 mx-auto md:mx-0 text-center md:text-start">
                          <span className="text-xs text-red-200">{error}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </Dialog.Title>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default LoginFlowCompanyInfoModal;
